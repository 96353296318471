import React from 'react';

const InfoMessage = ({ type, message }) => {
    return (
        <div className={`info-message info-message-${type} flex--row align-text--center align-items--center`}>
            <svg className={`icon icon--message-${type}`} />
            <p>{message}</p>
        </div>
    );
};

export default InfoMessage;
