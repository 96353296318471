import {PostNotification} from "../apiCalls/Post/PostNotification";

const HandleNotification = async (data, message, name) => {
    try {
        let wholeMessage = message + ' ' + name;
        let creatorId = data && data.properties ? data.properties?.createdById : data.createdById;

        await PostNotification(wholeMessage, creatorId);
    } catch (error) {
        console.error('Error during notification creation:', error);
    }
};

export default HandleNotification;