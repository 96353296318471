import React, {useEffect, useState} from 'react';
import apiUrls from "../../../../configs/api";
import GetById from "../../../../functions/apiCalls/Get/GetById";
import FormatDate from "../../../../functions/formats-and-regex/formatDate";

const InfoPageLog = ({ item }) => {
    const [createdBy, setCreatedByData] = useState('');
    const [editedBy, setEditedByData] = useState('');
    const [lockedBy, setLockedBy] = useState('');

    const createdDate = (item.createdDate || item.properties.createdDate);
    const editedDate = (item.lastEditedDate || item.properties.lastEditedDate);
    const itemProperties = item.properties ? item.properties : item;

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (item) {
                    const createdById = item.properties ? item.properties.createdById : item.createdById;
                    if (createdById) {
                        const data = await GetById(apiUrls.get.users, createdById);
                        if (data) {
                            setCreatedByData(data);
                        }
                    }
                    const lastEditedById = item.properties ? item.properties.lastEditedById : item.lastEditedById;
                    if (lastEditedById && lastEditedById !== 'Uendret') {
                        const data = await GetById(apiUrls.get.users, lastEditedById);
                        if (data) {
                            setEditedByData(data);
                        }
                    }
                    const lockedById = item.properties ? item.properties.editorLockUserId : item.editorLockUserId;
                    if (lockedById) {
                        const data = await GetById(apiUrls.get.users, lockedById);
                        if (data) {
                            setLockedBy(data);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, [item]);

    return (
        <div className="info-page--log flex--column">
            <div className="flex--row align-items--center info-page--log-headers">
                <p>Opprettet av</p>
                <p>Opprettelses Dato</p>
                <p>Sist Endret av</p>
                <p>Siste Endringsdato</p>
                <p>{itemProperties.editLock ? 'Låst av' : 'Låst'}</p>
            </div>
            <div className="flex--row align-items--center info-page--log-data">
                <p>{createdBy ? createdBy.username : 'Laster'}</p>
                <p>{FormatDate(createdDate)}</p>
                <p>{itemProperties.lastEditedById !== 'uendret' ? editedBy.username : itemProperties.lastEditedById}</p>
                <p>{itemProperties.lastEditedById !== 'Uendret' ? FormatDate(editedDate) : 'Uendret'}</p>
                <p>{itemProperties.editLock ? lockedBy.username : 'Nei'}</p>
            </div>
        </div>
    );
};

export default InfoPageLog;
