import React from 'react';
import statusOptions from '../../../../assets/data/jsonFiles/General/statuses.json';
import kumTypes from '../../../../assets/data/jsonFiles/Kummer/kum-types.json';
import spliceEnclosureTypes from '../../../../assets/data/jsonFiles/Kummer/splice-enclosure-types.json';

const NewConnectionInputs = ({ handleInputChange, formData, setFromData }) => {
    return (
        <div className="map--add-form-inputs flex--column">
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>Breddegrad</label>
                    <input
                        type='text'
                        id='latitude'
                        name='latitude'
                        placeholder='59.902712954570134'
                        value={formData.latitude}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    />
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Lengdegrad</label>
                    <input
                        type='text'
                        id='longitude'
                        name='longitude'
                        placeholder='10.783520510856157'
                        value={formData.longitude}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    />
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>Type</label>
                    <select
                        name="kumType"
                        id="kumType"
                        value={formData.kumType}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    >
                        {kumTypes.kumTypes.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Skjøte</label>
                    <select
                        name="spliceEnclosure"
                        id="spliceEnclosure"
                        value={formData.spliceEnclosure}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    >
                        {spliceEnclosureTypes.spliceEnclosureTypes.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>Navn</label>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        placeholder='Tilkoblingspunktet'
                        value={formData.name}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    />
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Status</label>
                    <select
                        name="status"
                        id="status"
                        value={formData.status}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    >
                        {statusOptions.statusOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column whole-row'>
                    <label>Notater</label>
                    <input
                        type='text'
                        id='notes'
                        name='notes'
                        placeholder='Viktig tilleggsinformasjon'
                        value={formData.notes}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    />
                </div>
            </div>
        </div>
    );
}
export default NewConnectionInputs;