import React from 'react';

const InfoPageData= ({headers, data}) => {
    return (
        <div className='flex--column'>
            <h3>Data</h3>
            <div className="flex--row info-page--data">
                <div className="flex--column info-page--data-headers">
                    {headers.map((header, index) => (
                        <p
                            key={index}
                            className="flex--row align-items--center"
                        >
                            <b>{header}</b>
                        </p>
                    ))}
                </div>
                <div className="flex--column info-page--data-data">
                    {data.map((datum, index) => (
                        <p
                            key={index}
                            className="flex--row align-items--center"
                        >
                            {datum}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InfoPageData;
