import firstUpperCase from "../../../functions/formats-and-regex/FirstUpperCase";
import React, {useState} from "react";
import apiUrls from "../../../configs/api";
import GetById from "../../../functions/apiCalls/Get/GetById";
import Update from "../../../functions/apiCalls/Update/Update";
import HandleNotification from "../../../functions/notifications/HandleNotification";

const RemoveNode = ({removeNodeMode, setRemoveNodeMode, homeownerAssocData, setHomeownerAssocData, nodes, id}) => {
    const [selectedNode, setSelectedNode] = useState('set-node');
    const [nodeDetails, setNodeDetails] = useState(null);

    const handleNodeChange = async (e) => {
        const nodeId = e.target.value;
        setSelectedNode(nodeId);

        if (nodeId !== 'set-node') {
            const node = await GetById(apiUrls.get.nodes, nodeId);
            setNodeDetails(node);
        } else {
            setNodeDetails(null);
        }
    };

    const handleRemoveNode = async (e) => {
        e.preventDefault();

        try {
            let bodyData = {
                homeownerAssocId: id,
                nodeId: selectedNode,
            }

            const response = await Update(id, apiUrls.put.removeNodeFromHomeownerAssoc, bodyData);

            if (response) {
                const updatedHomeownerAssoc = await GetById(apiUrls.get.homeownerAssocs, id);
                await HandleNotification(homeownerAssocData, 'Koblet borettslaget ' + homeownerAssocData.properties.name + ' fra noden', nodeDetails.name);
                if (updatedHomeownerAssoc) {
                    setHomeownerAssocData(updatedHomeownerAssoc);
                    setRemoveNodeMode(false);
                }
        } else {
                console.error('Failed to add node to homeowner association');
            }
        } catch (error) {
            console.error('Error adding node to homeowner association:', error);
        }
    };

    return (
        <form onSubmit={handleRemoveNode} className="flex--row align-items--center">
            <select
                name="node"
                value={selectedNode}
                onChange={handleNodeChange}
            >
                <option value="set-node">Velg Node</option>
                {nodes.map((node) => (
                    <option
                        key={node.id}
                        value={node.id}>
                        {node.name} ({firstUpperCase(node.nodeType)})
                    </option>
                ))}
            </select>

            <button
                type="submit"
                className={`btn btn-delete-white-bg ${selectedNode === 'set-node' ? 'disabled' : ''} m-l-10px `}
                disabled={selectedNode === 'set-node'}
            >
                Fjern
            </button>
        </form>
    );
}
export default RemoveNode;