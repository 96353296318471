const Post = async (endpoint, data) => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(`Failed to update data: ${errorMessage}`);
        }

        return response;
    } catch (error) {
        console.error('Error updating data:', error);
        throw error;
    }
};

export default Post;