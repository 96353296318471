import getById from "../apiCalls/Get/GetById";
import apiUrls from "../../configs/api";

const findMarkerCoordinate = (data) => {
    if (!data) return null;

    let coordinates = "";

    if (data.type === "Node" || data.type ==="Kum"){
        coordinates = data.coordinates;
        return [coordinates.latitude, coordinates.longitude];

    } else if (data.type === "Adresse") {
        const homeownerAssocPromise = getById(apiUrls.get.homeownerAssocs, data.homeownerAssosiationId);
        return homeownerAssocPromise.then(homeownerAssoc => {
            if (homeownerAssoc && homeownerAssoc.geometry && homeownerAssoc.geometry.coordinates) {
                coordinates = homeownerAssoc.geometry.coordinates[0];
                return [coordinates[0][1], coordinates[0][0]];
            } else {
                console.error("Invalid homeownerAssoc data:", homeownerAssoc);
                return null;
            }
        }).catch(error => {
            console.error("Error fetching homeownerAssoc:", error);
            return null;
        });

    } else if (data.geometry.type === "Polygon") {
        coordinates = data.geometry.coordinates[0];
        return [coordinates[0][1], coordinates[0][0]];
    }

    coordinates = data.geometry.coordinates;
    if (coordinates.length === 1) {
        return [coordinates[0][1], coordinates[0][0]];
    } else if (coordinates.length === 2) {
        const lat = (coordinates[0][1] + coordinates[1][1]) / 2;
        const lng = (coordinates[0][0] + coordinates[1][0]) / 2;
        return [lat, lng];
    }

    let maxLatitudeCoordinate = coordinates[0];
    let highestIndex = 0;
    for (let i = 1; i < coordinates.length; i++) {
        if (coordinates[i][1] >= maxLatitudeCoordinate[1]) {
            maxLatitudeCoordinate = coordinates[i];
            highestIndex = i;
        }
    }
    if (highestIndex === 0) {
        maxLatitudeCoordinate = coordinates[1];
    } else if (highestIndex === coordinates.length - 1) {
        maxLatitudeCoordinate = coordinates[coordinates.length - 2];
    }

    return [maxLatitudeCoordinate[1], maxLatitudeCoordinate[0]];
};

export default findMarkerCoordinate;