import firstUpperCase from "../../../functions/formats-and-regex/FirstUpperCase";
import React, {useEffect, useState} from "react";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../configs/api";
import GetById from "../../../functions/apiCalls/Get/GetById";
import Update from "../../../functions/apiCalls/Update/Update";
import FindIfFullNode from "../../../functions/page-spesific/infoPage/FindIfFullNode";
import HandleNotification from "../../../functions/notifications/HandleNotification";

const AddNode = ({addNodeMode, setAddNodeMode, homeownerAssocData, setHomeownerAssocData, nodes, id}) => {
    const [allNodes, setAllNodes] = useState([]);
    const [selectedNode, setSelectedNode] = useState('set-node');
    const [nodeDetails, setNodeDetails] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (addNodeMode) {
                const allNodes = await GetAll(apiUrls.get.nodes);
                setAllNodes(allNodes);
            }
        }
        fetchData();
    }, [addNodeMode]);

    const handleNodeChange = async (e) => {
        const nodeId = e.target.value;
        setSelectedNode(nodeId);

        if (nodeId !== 'set-node') {
            const node = await GetById(apiUrls.get.nodes, nodeId);
            setNodeDetails(node);
        } else {
            setNodeDetails(null);
        }
    };

    const handleAddNode = async (e) => {
        e.preventDefault();

        try {
            let bodyData = {
                homeownerAssocId: id,
                nodeId: selectedNode
            }
            const response = await Update(id,apiUrls.put.addNodeToHomeownerAssoc, bodyData);

            if (response) {
                const updatedHomeownerAssoc = await GetById(apiUrls.get.homeownerAssocs, id);
                await HandleNotification(homeownerAssocData, 'Koblet borettslaget ' + homeownerAssocData.properties.name + ' til noden', nodeDetails.name);
                if (updatedHomeownerAssoc) {
                    setHomeownerAssocData(updatedHomeownerAssoc);
                    setAddNodeMode(false);
                }
            } else {
                console.error('Failed to add node to homeowner association');
            }
        } catch (error) {
            console.error('Error adding node to homeowner association:', error);
        }
    };

    return (
        <form onSubmit={handleAddNode} className="flex--row align-items--center">
            <select
                name="node"
                value={selectedNode}
                onChange={handleNodeChange}
            >
                <option value="set-node">Velg Node</option>
                {allNodes
                    .filter(node => !nodes.some(homeownerNode => homeownerNode.id === node.id))
                    .map((node) => (
                        <option
                            key={node.id}
                            value={node.id}
                            disabled={FindIfFullNode(node)}
                        >
                            {node.name} ({firstUpperCase(node.nodeType)}) {FindIfFullNode(node) ? '(Fullt)' : ''}
                        </option>
                    ))}
            </select>

            <button type="submit" className={`btn btn-secondary-white-bg ${selectedNode === 'set-node' ? 'disabled' : ''}`}
                    disabled={selectedNode === 'set-node'}
            >
                Lagre
            </button>
        </form>
    );
}
export default AddNode;