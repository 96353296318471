import apiUrls from "../../../configs/api";

const Login = async (bodyData, setToken, setMessageData, setUsername, setPassword) => {
    const response = await fetch(apiUrls.login.loginUser, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
    });
    if (response.ok) {
        const data = await response.json();
        setToken(data);
    }
    else if (response.status === 401) {
        setMessageData({
            type: 'error',
            message: 'Feil brukernavn eller passord.'
        })
        setUsername('');
        setPassword('');
    }
    else {
        console.error('Login failed');
        console.error(await response.text());
        setMessageData({
            type: 'error',
            message: 'En feil oppsto. Prøv igjen.'
        })
        setUsername('');
        setPassword('');
    }
}
export default Login;