const LineRenderFilter = (layerFilters, item) => {
    if (item.properties.type === 'Rør') {
        if (!layerFilters.pipes.status && item.properties.status === "I bruk") {
            return false;
        }
        if (!layerFilters.pipes.tempClosed && item.properties.status === "Midlertidig stengt/Under vedlikehold") {
            return false;
        }
        if (!layerFilters.pipes.full && item.properties.filled === true) {
            return false;
        }
        return true;
    }
    else if (item.properties.type === 'Kabel'){
        if (!layerFilters.cables.status && item.properties.status === "I bruk") {
            return false;
        }
        if (!layerFilters.cables.tempClosed && item.properties.status === "Midlertidig stengt/Under vedlikehold") {
            return false;
        }
        return true;
    }
    else {
        return true;
    }
}

export default LineRenderFilter;