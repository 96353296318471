import React from 'react';
import { MapContainer, TileLayer, Marker, GeoJSON } from 'react-leaflet';
import { divIcon } from "leaflet/src/layer";

const MiniMap = ({ marker, icon, style, zoom, coordinates }) => {
    const getMarkerCoordinates = () => {
        if (marker) {
            if (marker.type === "Node" || marker.type === "Kum" || marker.type === "Adresse") {
                const { latitude, longitude } = marker.coordinates;
                return [latitude, longitude];
            } else if (marker.geometry.type === "LineString") {
                const coordinates = marker.geometry.coordinates;
                return [coordinates[0][1], coordinates[0][0]];
            } else if (marker.geometry.type === "Polygon") {
                const coordinates = marker.geometry.coordinates[0];
                return [coordinates[0][1], coordinates[0][0]];
            }
        }
        if (!marker) {
            return coordinates
        }
        return null;
    };
    const center = getMarkerCoordinates();

    const getMarker = (type) => {
        if (type) {
            return divIcon({
                className: `icon--${type}-marker`,
                iconSize: [36, 36],
                iconAnchor: [14, 34],
            });
        }
        return null;
    };

    return (
        center && (
            <MapContainer
                center={center}
                zoom={zoom}
                className="h-100vh w-25vw"
                scrollWheelZoom={false}
                doubleClickZoom={false}
                dragging={false}
                zoomControl={false}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {marker && marker.geometry && (marker.geometry.type === "Polygon" || marker.geometry.type === "LineString") && (
                    <GeoJSON
                        data={marker}
                        style={style}
                    />
                )}

                {marker && (
                    <Marker position={center} icon={getMarker(icon)} />
                )}
            </MapContainer>
        )
    );
};

export default MiniMap;
