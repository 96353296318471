import { divIcon } from "leaflet/src/layer";

const CreateIcon = ({ className, iconSize = [36, 36], iconAnchor = [14, 34] }) => {
    return divIcon({
        className: className,
        iconSize: iconSize,
        iconAnchor: iconAnchor,
    });
};

export default CreateIcon;


