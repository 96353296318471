import Update from "../../apiCalls/Update/Update";
import HandleNotification from "../../notifications/HandleNotification";

const HandleLockEdit = async (editLock, userId, item, setEditLock, apiUrl) => {
    try {
        const formData = {
            editLock: !editLock
        };
        const response = await Update(item.id, apiUrl, formData);
        if (response) {
            setEditLock(!editLock);
            await HandleNotification(item, "Låste redigering på",item.properties ? item.properties.name : item.name);
        }
    } catch (error) {
        console.error('Error updating edit lock:', error);
    }
}
export default HandleLockEdit;