import React from 'react';
import PopUpButtons from "./PopUpButtons";
import getAllPorts from "../../../functions/extractors/getAllPorts";

const PopupNoder = ({ item, handleUpdateCoordinates }) => {
    const getAvailablePorts = (node) => {
        let totalPorts = 0;
        if (item.switches) {
            item.switches.forEach((switcher) => {
                switcher.ports.forEach((port) => {
                    if (port.connectedEntityId === null) {
                        totalPorts++;
                    }
                });
            });
        }
        return totalPorts;
    }

    return (
        <div>
            {item && (
                <div className="flex--column justify-content-center map--popup">
                    <div className="flex--row align-items--center map-popup-header">
                        <svg className={`icon--node${item.nodeType==="Dist" ? "-dist" : ""}`}/>
                        <h4>{item.name}</h4>
                    </div>

                    <div className="flex--row justify-content-sb map--popup-body">
                        <div className="flex--column justify-content-center map--popup-info">
                            <div className="flex--row long">
                                <p><b>Status:</b></p>
                                <p>{item.status}</p>
                            </div>
                            <div className="flex--row long">
                                <p><b>Type:</b></p>
                                <p>{item.nodeType}</p>
                            </div>
                            <div className="flex--row long">
                                <p><b>Switcher:</b></p>
                                <p>{item.switches ? item.switches.length : 0 }</p>
                            </div>
                            <div className="flex--column tall">
                                <p><b>Ledige Porter:</b> </p>
                                <p>{getAvailablePorts(item)} av {getAllPorts(item)}</p>
                            </div>
                            <div className="flex--column tall">
                                <p><b>Adresse:</b></p>
                                <p>{item.address}, {item.floor}. Etasje</p>
                            </div>
                        </div>

                        <PopUpButtons
                            item={item}
                            type="point"
                            endpoint="node"
                            handleUpdateCoordinates={handleUpdateCoordinates}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PopupNoder;
