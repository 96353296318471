const PostUser = async (endpoint, data) => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        return {
            status: response.status,
            message: responseData.message || 'No message provided'
        };
    } catch (error) {
        console.error('Error updating data:', error);
        throw error;
    }
};

export default PostUser;