import React from 'react';

const OpenFormButton = ({ formType, selectedForm, onClick, label }) => {
    return (
        <button
            className={`btn btn-secondary ${selectedForm === formType ? 'active' : ''}`}
            onClick={() => onClick(formType)}
        >
            {label}
        </button>
    );
};

export default OpenFormButton;