export const initialLayerFilters = {
    kummer: {
        all: true,
        status: true,
        tempClosed: true,
        spliceEnclosure: true,
        wallLocker: true,
        kum: true,
        available: true,
        spliceEnclosureB5: true,
        spliceEnclosureB4: true,
        spliceEnclosureNone: true
    },
    noder: {
        all: true,
        status: true,
        tempClosed: true,
        dist: true,
        node: true,
        available: true
    },
    cables: {
        all: true,
        status: true,
        tempClosed: true,
        available: true,
        icons: true
    },
    pipes: {
        all: true,
        status: true,
        tempClosed: true,
        available: true,
        full: true,
        icons: true
    },
    homeownerAssocs: {
        all: true,
        operational: true,
        completed: true,
        beingBuilt: true,
        inPlanning: true,
        unconnectedUnits: true,
        allConnectedUnits: true,
        icons: true
    },
    all: {
        drawing: true,
        locked: true,
        icons: true,
        beingBuilt: true,
        tempClosed: true
    }
};