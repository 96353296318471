import bannerImg from "../../assets/images/bannerImg.png"

const closeMessage = () => {
    const element = document.querySelector(".info-banner");
    element.remove();
}

const InfoBanner = ({item}) => {
    return (
        <div className={`flex--row info-banner info-banner--${item.type}`}>
            <div className="info-banner--img flex--column justify-content-center align-items--center">
                <img src={bannerImg} alt="Illustrasjon av veier"/>
            </div>
            <div className="flex--row info-banner--content justify-content-sb ">
                <div className="flex--column info-banner--text justify-content-center align-text--left">
                    <h3>{item.header}</h3>
                    <p>{item.message}</p>
                </div>
                <p
                    className="info-banner--close-btn"
                    onClick={closeMessage}
                >
                    <b>LUKK X</b></p>
            </div>
        </div>
    );
}
export default InfoBanner;