import React, { useState } from "react";
import HandleLockEdit from "../../functions/page-spesific/infoPage/HandleEditLock";
import GetAll from "../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../configs/api";
import DeletePopup from "../messagesAndPopups/DeletePopup";

const HeaderButtons = ({ editMode, setEditMode, editLock, setEditLock, userId, userType, item, apiUrlLock, apiUrlDelete }) => {
    let buttonDisabled = item && (item.properties ? item.properties.editLock : item.editLock) && !(userId === (item.properties ? item.properties.editorLockUserId : item.editorLockUserId) || userType === "Admin" || userType === "Utvikler");
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [connectionData, setConnectionData] = useState([]);

    const handleDelete = async () => {
        if (item.properties?.type === 'Rør') {
            const pipes = await GetAll(apiUrls.get.pipes);
            const cables = await GetAll(apiUrls.get.cables);

            const combinedData = [...pipes, ...cables];
            setConnectionData(combinedData);
        }
        setShowDeletePopup(true);
    };

    return (
        <div className="info-page--content-header-buttons flex--row">
            {!editMode && (
                <div>
                    <button
                        onClick={() => HandleLockEdit(editLock, userId, item, setEditLock, apiUrlLock)}
                        className={`btn btn-primary-white-bg ${editLock ? (!buttonDisabled ? 'active' : 'active disabled') : ''} flex--row justify-content-center align-items--center lock-button`}
                        disabled={buttonDisabled}
                    >
                        {!editLock ? 'Lås Redigering' : 'Åpne Redigering'}
                        <svg className={`icon--lock-${editLock ? 'closed-blue' : 'open-white'}`}/>
                    </button>
                    <div className="helping-box helping-box--lock">
                        {!editLock ? (
                            <p>Klikk for å låse objektet slik at andre ikke kan gjøre endringer.</p>
                        ) : (
                            <p>Klikk for å åpne objektet slik at andre kan gjøre endringer.</p>
                        )}
                    </div>
                </div>
            )}

            <button
                onClick={() => setEditMode(prevEditMode => !prevEditMode)}
                className={`btn btn-secondary-white-bg ${editLock ? (!buttonDisabled ? (editMode ? 'active' : '') : 'disabled') : (editMode ? 'active' : '')} flex--row justify-content-center align-items--center m-l-10px`}
                disabled={buttonDisabled}
            >
                {!editMode ? 'Rediger' : 'Avbryt'}
                <svg className={`icon--${editMode ? 'green' : (editLock && buttonDisabled) ? 'green' : 'white'}-edit`}/>
            </button>

            <button
                onClick={handleDelete}
                className={`btn btn-delete-white-bg ${editLock ? (!buttonDisabled ? '' : 'disabled') : ''} flex--row justify-content-center align-items--center m-l-10px`}
                disabled={buttonDisabled}
            >
                Slett
                <svg className={`icon--delete-${(editLock && buttonDisabled) ? 'red' : 'white'}`} />
            </button>

            {showDeletePopup && (
                <DeletePopup
                    item={item}
                    connectionData={connectionData}
                    setShowDeletePopup={setShowDeletePopup}
                    apiUrlDelete={apiUrlDelete}
                    redirect={true}
                />
            )}
        </div>
    );
};

export default HeaderButtons;
