const FindIfFullHomeownerAssoc = (homeownerAssoc) => {
    try {
        if (homeownerAssoc && homeownerAssoc.properties) {
            let allApartments = homeownerAssoc.properties.apartmentCount;
            let connectedApartments = homeownerAssoc.properties.portConnections;

            const apartmentsWithoutConnection = allApartments - connectedApartments;
            return apartmentsWithoutConnection === 0;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error finding homeowner assoc:', error);
        return false;
    }
}
export default FindIfFullHomeownerAssoc;