import HeaderLoggedIn from "../../components/headers/headerLoggedIn";
import ProfileMap from "../../components/maps/ProfileMap";
import profilePicture from "../../assets/images/ProfilePic.svg"
import React, {useState, useEffect} from "react";
import EditUserForm from "../../components/forms/EditUserForm";
import Dashboard from "../../components/displays/Dashboard";
import SendFeedbackForm from "../../components/forms/SendFeedbackForm";
import AdminDisplay from "../../components/displays/AdminDisplay";
import DeveloperDisplay from "../../components/displays/DeveloperDisplay";
import GetById from "../../functions/apiCalls/Get/GetById";
import apiUrls from "../../configs/api";

const ProfilePage = () => {
    const userId = sessionStorage.getItem("userId");
    const [user, setUser] = useState(null);
    const [activeForm, setActiveForm] = useState('dashboard');
    const [userChanges, setUserChanges] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await GetById(apiUrls.get.users, userId);
            if (user) {
                setUser(user);
                sessionStorage.setItem("username", user.username);
            }
        };
        fetchUser();
    }, [userChanges]);


    const toggleForm = (form) => {
        setActiveForm(prevForm => (prevForm === form ? 'dashboard' : form));
    };

    return (
        <div className="profile">
            <HeaderLoggedIn/>
            <ProfileMap/>
            {user && (
                <>
                    <div className="flex--column profile--user">
                        <img className="" src={profilePicture} alt="Standard profilbilde"/>
                        <h2 className="flex--column justify-content-center align-items--center">{user.username}</h2>
                    </div>
                    <div className="profile--body flex--row">
                        <div className="profile--nav  flex--column justify-content-center">
                            <div className="profile--nav-buttons flex--column justify-content-center align-items--center">
                                <button
                                    className={`btn btn-primary-white-bg ${activeForm === 'dashboard' ? 'active' : ''}`}
                                    onClick={() => toggleForm('dashboard')}
                                >
                                    Mitt Dashboard
                                </button>
                                <button
                                    className={`btn btn-primary-white-bg ${activeForm === 'editUser' ? 'active' : ''}`}
                                    onClick={() => toggleForm('editUser')}
                                >
                                    Endre Bruker
                                </button>

                                {(user.type === "Admin" || user.type === "Utvikler") && (
                                    <button
                                        className={`btn btn-primary-white-bg ${activeForm === 'adminDisplay' ? 'active' : ''}`}
                                        onClick={() => toggleForm('adminDisplay')}
                                    >
                                        Administrer
                                    </button>
                                )}

                                {(user.type === "Admin" || user.type === "Ordinær") && (
                                    <button
                                        className={`btn btn-primary-white-bg ${activeForm === 'sendFeedback' ? 'active' : ''}`}
                                        onClick={() => toggleForm('sendFeedback')}
                                    >
                                        Tilbakemelding
                                    </button>
                                )}

                                {user.type === "Utvikler" && (
                                    <button
                                        className={`btn btn-primary-white-bg ${activeForm === 'developerDisplay' ? 'active' : ''}`}
                                        onClick={() => toggleForm('developerDisplay')}
                                    >
                                        Utvikling
                                    </button>
                                )}
                            </div>
                        </div>
                        <div
                            className="profile--display flex--column justify-content-center align-items--center">
                            {activeForm === 'dashboard' && <Dashboard activeForm={activeForm} user={user} />}
                            {activeForm === 'adminDisplay' && <AdminDisplay/>}
                            {activeForm === 'editUser' && <EditUserForm user={user} setUserChanges={setUserChanges} />}
                            {activeForm === 'sendFeedback' && <SendFeedbackForm/>}
                            {activeForm === 'developerDisplay' && <DeveloperDisplay/>}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export default ProfilePage;