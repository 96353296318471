import GetAll from "./Get/GetAll";
import apiUrls from "../../configs/api";

const CheckToken = async (retries = 1, backoff = 300) => {
    try {
        const response = await GetAll(apiUrls.authentication.checkToken);
        if (response.token) {
            sessionStorage.setItem("token", response.token);
        }
        else {
            sessionStorage.clear();
            window.location.href = '/';
        }
    } catch (error) {
        if (retries > 0) {
            await new Promise(res => setTimeout(res, backoff));
            return CheckToken(retries - 1, backoff * 2);
        } else {
            console.error('Ugyldig token. Du blir logget deg ut:', error);
            sessionStorage.clear();
            window.location.href = '/';
        }
    }
};
export default CheckToken;