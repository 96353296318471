import apiUrls from "../../configs/api";
import GetById from "../apiCalls/Get/GetById";

const getAllNodesPorts = async (nodeId) => {
    try {
        const node = await GetById(apiUrls.get.nodes, nodeId);
        if (!node || !node.switches) {
            return [];
        }

        const portsList = [];
        node.switches.forEach((sw, switchIndex) => {
            sw.ports.forEach((port) => {
                portsList.push({
                    label: `Switch ${switchIndex + 1}, Port ${port.number}`,
                    value: switchIndex + ',' + port.number,
                    taken: port.connectedEntityId !== null
                });
            });
        });
        return portsList;
    } catch (error) {
        console.error("Error fetching node ports:", error);
        return [];
    }
};

export default getAllNodesPorts;


