import React, { useState, useEffect } from 'react';

const InfoPageEditHeader = ({ name, icon, setNewName }) => {
    const [editedName, setEditedName] = useState(name);

    const handleChange = (event) => {
        const newName = event.target.value;
        setEditedName(newName);
        setNewName(newName);
    };

    useEffect(() => {
        setEditedName(name);
    }, [name]);

    return (
        <div className="d-flex justify-content-center align-items--center info-page--name-input">
            <svg className={`icon--${icon}`} />
            <input
                type="text"
                className=""
                value={editedName}
                onChange={handleChange}
            />
        </div>
    );
};

export default InfoPageEditHeader;

