import {handleNext, handlePrevious} from "../../functions/page-spesific/map-add/HandlePrevNext";
import React from "react";

const AddMapObjectsIndexButtons = ({currentGroupIndex, setCurrentGroupIndex, stepsCount}) => {
    return (
        <div className='flex--row'>
            {currentGroupIndex !== 0 && (
                <button
                    type="button"
                    onClick={() => handlePrevious(setCurrentGroupIndex)}
                    className="btn btn-primary-white-bg"
                >
                    <div className="flex--row justify-content-center">
                        Forrige
                    </div>
                </button>
            )}
            {currentGroupIndex === stepsCount - 1 && (
                <button
                    className="btn btn-secondary-white-bg"
                    type="submit"
                >
                    Opprett
                </button>
            )}
            {currentGroupIndex < stepsCount - 1 && (
                <button
                    type="button"
                    onClick={() => handleNext(setCurrentGroupIndex, stepsCount)}
                    className="btn btn-primary-white-bg"
                >
                    <div className="flex--row justify-content-center">
                        Neste
                    </div>
                </button>
            )}
        </div>
    );
}
export default AddMapObjectsIndexButtons;