import React, {useEffect, useRef, useState} from "react";
import UpdateUser from "../../functions/apiCalls/Update/UpdateUser";
import apiUrls from "../../configs/api";

const SmsCode = ({changePhoneNumber, setChangePhoneNumber, formData, userId, setMessageData}) => {
    const [smsCode, setSmsCode] = useState({
        num1: '',
        num2: '',
        num3: '',
        num4: ''
    });
    const hiddenInputRef = useRef(null);

    const handleSmsSubmit = async () => {
        try {
            const code = smsCode.num1 + smsCode.num2 + smsCode.num3 + smsCode.num4;
            const data = {
                newPhoneNumber: formData.phoneNumber,
                smsCode: code
            }
            let response = await UpdateUser(userId, apiUrls.put.verifyPhoneNumber, data);
            if (response.status === 200) {
                setMessageData({
                    type: 'success',
                    message: 'Bruker oppdatert.'
                });
                setChangePhoneNumber(false);
            } else {
                setMessageData({
                    type: 'warning',
                    message: 'Feil SMS-kode. Prøv igjen.'
                });
                setSmsCode({
                    num1: '',
                    num2: '',
                    num3: '',
                    num4: ''
                })
            }
        } catch (error) {
            setMessageData({
                type: 'warning',
                message: 'En feil oppstod under SMS-verifisering.'
            });
        }
    };

    const handleSmsInput = (e) => {
        const value = e.key;
        if (/\d/.test(value)) {
            setSmsCode((prev) => {
                const updatedCode = { ...prev };
                if (!prev.num1) updatedCode.num1 = value;
                else if (!prev.num2) updatedCode.num2 = value;
                else if (!prev.num3) updatedCode.num3 = value;
                else if (!prev.num4) updatedCode.num4 = value;
                return updatedCode;
            });
        }
    };

    useEffect(() => {
        if (smsCode.num1 && smsCode.num2 && smsCode.num3 && smsCode.num4) {
            handleSmsSubmit();
        }
    }, [smsCode]);

    useEffect(() => {
        const handleClickOutside = () => {
            if (changePhoneNumber && hiddenInputRef.current) {
                hiddenInputRef.current.focus();
            }
        };
        const timer = setTimeout(() => {
            if (changePhoneNumber && hiddenInputRef.current) {
                hiddenInputRef.current.focus();
            }
        }, 0);
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            clearTimeout(timer);
        };
    }, [changePhoneNumber]);

    const GoBackToForm = () => {
        setChangePhoneNumber(false);
        setSmsCode({
            num1: '',
            num2: '',
            num3: '',
            num4: ''
        });
    }

    return (
        <form className="flex--column m-s-auto sms-code--form " onSubmit={handleSmsSubmit}>
            <div className="sms-code--inputs flex--row justify-content-se">
                <input
                    type="text"
                    value={smsCode.num1}
                    maxLength={1}
                    readOnly
                />
                <input
                    type="text"
                    value={smsCode.num2}
                    maxLength={1}
                    readOnly
                />
                <input
                    type="text"
                    value={smsCode.num3}
                    maxLength={1}
                    readOnly
                />
                <input
                    type="text"
                    value={smsCode.num4}
                    maxLength={1}
                    readOnly
                />
            </div>
            <div className="flex--row justify-content-center align-items--center">
                <button className="btn btn-primary-white-bg" type="button"
                        onClick={() => GoBackToForm()}>
                    Tilbake
                </button>
            </div>
            <input
                className="input-hidden"
                type="text"
                ref={hiddenInputRef}
                onKeyUp={handleSmsInput}
            />
        </form>
    );
}
export default SmsCode;