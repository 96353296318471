import VersionInfo from "./VersionInfo";
import obosLogo from "../../assets/images/OBOSNetLogo.png";
import spotsLogo from "../../assets/images/spotsLogo2.png";
const HeaderNotLoggedIn = () => {

    return (
        <header className="flex--row justify-content-left align-items--center h-10vh header--nli">
            <img src={obosLogo} alt="Obos opennet logo" className="header--obos-logo"/>
            <div className="flex--row header--spots align-items--center justify-content-center">
                <img src={spotsLogo} alt="Spots Logo" className="header--spots-logo"/>
                <div className="flex--column align-items--center justify-content-center">
                    <h3>SPOTS</h3>
                    <p><VersionInfo/></p>
                </div>
            </div>
        </header>
    );
};

export default HeaderNotLoggedIn;