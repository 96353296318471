import React, { useEffect, useState } from "react";
import Update from "../../../../../functions/apiCalls/Update/Update";
import apiUrls from "../../../../../configs/api";
import statusOptions from "../../../../../assets/data/jsonFiles/HomeownerAssocs/homeownerAssoc_statuses.json";
import HandleNotification from "../../../../../functions/notifications/HandleNotification";

const InfoPageEditHomeownerAssocContents = ({ homeownerAssoc, setEditMode, newName }) => {
    const userId = sessionStorage.getItem("userId");
    const [editedHomeownerAssoc, setEditedHomeownerAssoc] = useState({
        name: newName,
        address: homeownerAssoc.properties.address,
        status: homeownerAssoc.properties.status,
        apartmentCount: homeownerAssoc.properties.apartmentCount,
        lastEditedById: userId,
        notes: homeownerAssoc.properties.notes
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditedHomeownerAssoc({ ...editedHomeownerAssoc, [name]: value });
    };

    useEffect(() => {
        setEditedHomeownerAssoc(prevState => ({
            ...prevState,
            name: newName
        }));
    }, [newName]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updatedData = {
                name: editedHomeownerAssoc.name === '' ? homeownerAssoc.properties.name : newName,
                status: editedHomeownerAssoc.status,
                address: editedHomeownerAssoc.address,
                apartmentCount: parseInt(editedHomeownerAssoc.apartmentCount),
                lastEditedById: userId,
                notes: editedHomeownerAssoc.notes
            };

            await Update(homeownerAssoc.id, apiUrls.put.updateHomeownerAssocInfo, updatedData);
            await HandleNotification(homeownerAssoc, 'Endret informasjonen til borettslaget', homeownerAssoc.properties.name);
            setEditMode(false);
        } catch (error) {
            console.error("Failed to update data:", error);
        }
    };

    return (
        <div className="info-page--content-data">
            <form className="info-page--edit-info flex--column" onSubmit={handleSubmit}>
                <label>Status</label>
                <select
                    name="status"
                    id="status"
                    value={editedHomeownerAssoc.status}
                    onChange={handleChange}
                >

                    {statusOptions.homeownerAssocsStatusOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                {!homeownerAssoc.properties.netadminData && (
                    <>
                        <label htmlFor="address">Adresse</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={editedHomeownerAssoc.address}
                            onChange={handleChange}
                        />

                        <label htmlFor="apartmentCount">Antall Leiligheter</label>
                        <input
                            type="number"
                            min={1}
                            id="apartmentCount"
                            name="apartmentCount"
                            value={editedHomeownerAssoc.apartmentCount}
                            onChange={handleChange}
                        />
                    </>
                )}

                <label>Notat</label>
                <input
                    type="text"
                    id="notes"
                    name="notes"
                    className="wide-input"
                    value={editedHomeownerAssoc.notes}
                    onChange={handleChange}
                />

                <div className="flex--row justify-content-center align-items--center">
                    <button className="btn btn-secondary-white-bg" type="submit">
                        Lagre Endringer
                    </button>
                </div>
            </form>
        </div>
    )
        ;
};
export default InfoPageEditHomeownerAssocContents;
