const fixNumber = (number) => {
    if (number < 10) {
        return '0' + number;
    } else {
        return number.toString();
    }
};

const FormatDate = (dateTimeString) => {
    const dateToday = new Date(dateTimeString);

    let date = fixNumber(dateToday.getDate());
    let month = fixNumber(dateToday.getMonth() + 1);
    let year = fixNumber(dateToday.getFullYear());

    const formattedDate = `${date}.${month}.${year}`;

    let minutes = fixNumber(dateToday.getMinutes());
    let hours = fixNumber(dateToday.getHours());

    const formattedTime = `${hours}:${minutes}`;
    return `${formattedDate} ${formattedTime}`;
};

export default FormatDate;
