
const baseUrl = process.env.REACT_APP_API_URL;

const apiUrls = {
    getForMap: {
        kummer: `${baseUrl}/Kummer`,
        nodes: `${baseUrl}/Nodes`,
        cables: `${baseUrl}/Cables`,
        pipes: `${baseUrl}/Pipes`,
        homeownerAssocs: `${baseUrl}/HomeownerAssocs`,
    },
    get: {
        kummer: `${baseUrl}/Kummer`,
        nodes: `${baseUrl}/Nodes`,
        cables: `${baseUrl}/Cables`,
        pipes: `${baseUrl}/Pipes`,
        homeownerAssocs: `${baseUrl}/HomeownerAssocs`,
        addresses: `${baseUrl}/ResidenceAddresses`,
        users: `${baseUrl}/Users`,

        updates: `${baseUrl}/Update`,
        pdfsAllForObject: `${baseUrl}/Pdfs/all`,
        imagesAllForObject: `${baseUrl}/Images/all`
    },
    post: {
        createKum: `${baseUrl}/Kummer/create`,
        createNode: `${baseUrl}/Nodes/create`,
        createCable: `${baseUrl}/Cables/create`,
        createPipe: `${baseUrl}/Pipes/create`,
        createHomeownerAssoc: `${baseUrl}/HomeownerAssocs/create`,
        createAddress: `${baseUrl}/ResidenceAddresses/create`,

        createNotification:`${baseUrl}/Notifications/create`,
        createFeedback: `${baseUrl}/Feedback/create`,
        createPdf: `${baseUrl}/Pdfs/create`,
        createImage: `${baseUrl}/Images/create`
    },
    put: {
        updateKum: `${baseUrl}/Kummer/update`,
        updateNode: `${baseUrl}/Nodes/update`,
        updateCable: `${baseUrl}/Cables/update`,
        updatePipe: `${baseUrl}/Pipes/update`,
        updateHomeownerAssoc: `${baseUrl}/HomeownerAssocs/update`,
        updateAddress: `${baseUrl}/ResidenceAddresses/update`,

        updateKumCoordinates: `${baseUrl}/Kummer/update-coordinates`,
        updateNodeCoordinates: `${baseUrl}/Nodes/update-coordinates`,
        updateCableCoordinates: `${baseUrl}/Cables/update-coordinates`,
        updatePipeCoordinates: `${baseUrl}/Pipes/update-coordinates`,
        updateHomeownerAssocCoordinates: `${baseUrl}/HomeownerAssocs/update-coordinates`,

        updateKumInfo: `${baseUrl}/Kummer/update-info`,
        updateNodeInfo: `${baseUrl}/Nodes/update-info`,
        updateCableInfo: `${baseUrl}/Cables/update-info`,
        updatePipeInfo: `${baseUrl}/Pipes/update-info`,
        updateAddressInfo: `${baseUrl}/ResidenceAddresses/update-info`,
        updateHomeownerAssocInfo: `${baseUrl}/HomeownerAssocs/update-info`,

        updatePipeFilledStatus: `${baseUrl}/Pipes/update-filled`,

        updateUserInfo: `${baseUrl}/Users/update-user`,
        updateUserPassword: `${baseUrl}/Users/update-user-password`,
        verifyPhoneNumber: `${baseUrl}/Users/verify-phone`,

        addNodeToHomeownerAssoc: `${baseUrl}/HomeownerAssocs/add-nodes`,
        removeNodeFromHomeownerAssoc: `${baseUrl}/HomeownerAssocs/remove-nodes`,

        updateEditLockKum: `${baseUrl}/Kummer/update-lock`,
        updateEditLockNode: `${baseUrl}/Nodes/update-lock`,
        updateEditLockPipe: `${baseUrl}/Pipes/update-lock`,
        updateEditLockCable: `${baseUrl}/Cables/update-lock`,
        updateEditLockHomeownerAssoc: `${baseUrl}/HomeownerAssocs/update-lock`,

        addFilterPreferenceToUser: `${baseUrl}/Users/update-filter-preference`,
        removeFilterPreferenceToUser: `${baseUrl}/Users/remove-filter-preference`,
    },
    delete: {
        deleteKum: `${baseUrl}/Kummer/delete`,
        deleteNode: `${baseUrl}/Nodes/delete`,
        deleteCable: `${baseUrl}/Cables/delete`,
        deletePipe: `${baseUrl}/Pipes/delete`,
        deleteHomeownerAssoc: `${baseUrl}/HomeownerAssocs/delete`,
        deleteAddress: `${baseUrl}/ResidenceAddresses/delete`,
        
        deletePdf: `${baseUrl}/Pdfs/delete`,
        deleteImage: `${baseUrl}/Images/delete`
    },
    login: {
        loginUser: `${baseUrl}/Users/login`,
    },
    search: {
        search: `${baseUrl}/Search`,
    },
    admin: {
        createUser: `${baseUrl}/Users/create`,
        changeUser: `${baseUrl}/Users/admin-update-user`,
        deleteUser: `${baseUrl}/Users/delete`
    },
    developer: {
        feedback: `${baseUrl}/Feedback`,
        postUpdate: `${baseUrl}/Update/create`,
    },
    authentication : {
        checkToken: `${baseUrl}/Users/check-token`,
    },
    netamin : {
        getHomeownerAssocs: `${baseUrl}/Netadmin/GetHomeownerAssocData`
    },
    openstreetmap: {
        getCoordinatesFromAddress: `https://nominatim.openstreetmap.org/search?format=json&countrycodes=NO&q=`
    }
};


export default apiUrls;
