const AllRenderFilter = (layerFilters, item) => {
    const itemStatus = item.properties ? item.properties.status : item.status;

    if (!layerFilters.all.drawing && itemStatus === "Kladd") {
        return false;
    }
    if (!layerFilters.all.locked && ( item.properties ? item.properties.editLock === true : item.editLock === true)) {
        return false;
    }
    if (!layerFilters.all.beingBuilt && (itemStatus === "Bygges" || itemStatus === "Under planlegging")) {
        return false;
    }
    if (!layerFilters.all.tempClosed && itemStatus === "Midlertidig stengt/Under vedlikehold") {
        return false;
    }
    else {
        return true;
    }
}

export default AllRenderFilter;