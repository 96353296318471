import FindIfFullNode from "../../page-spesific/infoPage/FindIfFullNode";

const PointRenderFilter = (layerFilters, item) => {
    if (item.type === 'Node') {
        if (!layerFilters.noder.status && item.status === "I bruk") {
            return false;
        }
        if (!layerFilters.noder.available && FindIfFullNode(item)) {
            return false;
        }
        if (!layerFilters.noder.tempClosed && item.status === "Midlertidig stengt/Under vedlikehold") {
            return false;
        }
        if (!layerFilters.noder.dist && item.nodeType === "Dist") {
            return false;
        }
        if (!layerFilters.noder.node && item.nodeType === "Node") {
            return false;
        }
        return true;
    }
    else if (item.type === 'Kum'){
        if (!layerFilters.kummer.status && item.status === "I bruk") {
            return false;
        }
        if (!layerFilters.kummer.spliceEnclosure && item.spliceEnclosure === false) {
            return false;
        }
        if (!layerFilters.kummer.tempClosed && item.status === "Midlertidig stengt/Under vedlikehold") {
            return false;
        }
        if (!layerFilters.kummer.kum && (item.kumType === "Minikum" || item.kumType === "EnkeltLokk (TK1)" || item.kumType === "Dobbeltlokk (TK2)" || item.kumType === "TK3" || item.kumType === "Rundkum")) {
            return false;
        }
        if (!layerFilters.kummer.wallLocker && (item.kumType === "Veggskap (Innvendig)" || item.kumType === "Veggskap (Utvendig)")) {
            return false;
        }
        if (!layerFilters.kummer.spliceEnclosureB5 && item.spliceEnclosure === "B5") {
            return false;
        }
        if (!layerFilters.kummer.spliceEnclosureB4 && item.spliceEnclosure === "B4") {
            return false;
        }
        if (!layerFilters.kummer.spliceEnclosureNone && item.spliceEnclosure === "Ingen") {
            return false;
        }
        return true;
    }
    else {
        return true;
    }
}

export default PointRenderFilter;