import apiUrls from "../../../configs/api";

const PostNotification = async (message, creatorId) => {
    const token = sessionStorage.getItem('token');
    try {
        const notificationData = {
            affectedUserId: creatorId,
            message: message,
        };

        const response = await fetch(apiUrls.post.createNotification, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(notificationData),
        });

        if (!response.ok) {
            console.error('Failed to create notification:', response.statusText);
        }
    } catch (error) {
        console.error('Error during notification creation:', error);
    }
};

export { PostNotification };