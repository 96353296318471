import React, {useEffect, useState} from 'react';
import apiUrls from "../../../configs/api";
import GetById from "../../../functions/apiCalls/Get/GetById";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import PopUpButtons from "./PopUpButtons";

const PopupCables = ({ item, handleUpdateCoordinates }) => {
    const [pipe, setPipeData] = useState('');
    const [kummer, setKummerData] = useState([]);
    const [nodes, setNodesData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (item.properties.pipeId !== null) {
                    const data = await GetById(apiUrls.get.pipes, item.properties.pipeId);
                    if (data) {
                        setPipeData(data);
                    }
                }
                if (item.properties.kummer && item.properties.kummer.length > 0) {
                    const cableKumIds = item.properties.kummer.map(cableKum => cableKum.id);

                    const allKummer = await GetAll(apiUrls.get.kummer);

                    const filteredKummer = allKummer.filter(kum =>
                        kum && kum.cables &&
                        kum.cables.some(cableKum => cableKumIds.includes(cableKum.id))
                    );

                    setKummerData(filteredKummer);
                }
                if (item && item.properties && item.properties.nodes && item.properties.nodes.length > 0) {
                    const cableNodeIds = item.properties.nodes.map(cableNode => cableNode.pointId);

                    const allNodes = await GetAll(apiUrls.get.nodes);

                    const filteredNodes = allNodes.filter(node =>
                        cableNodeIds.includes(node.id)
                    );

                    setNodesData(filteredNodes);
                }
            } catch (error) {
                console.error('Error fetching pipe data:', error);
            }
        };
        fetchData();
    }, [item.properties.pipeId]);

    return (
        <div className="flex--column justify-content-center map--popup">
            <div className="flex--row align-items--center map-popup-header">
                <svg className="icon--cable" />
                <h4>{item.properties.name}</h4>
            </div>
            <div className="flex--row justify-content-sb map--popup-body">
                <div className="flex--column map--popup-info">
                    <div className="flex--row long">
                        <p><b>Status:</b></p>
                        <p>{item.properties.status}</p>
                    </div>
                    <div className="flex--row long">
                        <p><b>Type:</b></p>
                        <p>{item.properties.cableType}</p>
                    </div>
                    <div className="flex--row long">
                        <p><b>I Rør: </b></p>
                        {item.properties.pipeId === null ?
                            <p>Nei</p>
                            :
                            <p>{pipe && pipe.properties.name}</p>
                        }
                    </div>
                    <div className="flex--column tall">
                        <p><b>Kummer Tilkoblet:</b></p>
                        <div className="flex--row map--popup-list">
                            {kummer.length > 0 ?
                                kummer.map((kum, index) => (
                                    <a href={`/kum/${kum.id}`} key={index}>{kum.name}</a>
                                )) : (
                                    <p>Ingen</p>
                                )
                            }
                        </div>
                    </div>
                    <div className="flex--column tall">
                        <p><b>Noder Tilkoblet:</b></p>
                        <div className="flex--row map--popup-list">
                            {nodes.length > 0 ?
                                nodes.map((node, index) => (
                                    <a href={`/node/${node.id}`} key={index}>{node.name}</a>
                                )) : (
                                    <p>Ingen</p>
                                )
                            }
                        </div>
                    </div>
                </div>
                <PopUpButtons
                    item={item}
                    type="line"
                    endpoint="kabel"
                    handleUpdateCoordinates={handleUpdateCoordinates}
                />
            </div>
        </div>
    );
};

export default PopupCables;