import React from 'react';

const InfoPageHeader = ({ name, icon }) => {
    return (
        <h2 className="d-flex justify-content-center align-items--center info-page--name">
            <svg className={`icon--${icon}`} />
            {name}
        </h2>
    );
};

export default InfoPageHeader;