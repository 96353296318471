import React from "react";
import GetConnectionName from "../../functions/extractors/GetConnectionName";
import Delete from "../../functions/apiCalls/Delete";
import HandleNotification from "../../functions/notifications/HandleNotification";

const DeletePopup = ({item, connectionData, setShowDeletePopup, apiUrlDelete, redirect, setData, setRefresh}) => {
    const confirmDelete = async (objectId) => {
        try {
            if (!apiUrlDelete) {
                throw new Error("API URL not set for deletion");
            }
            await deleteObject();
            await HandleNotification(item, 'Slettet', item.properties ? item.properties.name : item.name);
            if (redirect) {
                window.location.href = '/kart';
            } else {
                setData(prevData => prevData.filter(item => item.id !== objectId));
                setRefresh(Math.random());
            }
        } catch (error) {
            console.error('Error during deletion:', error);
        } finally {
            setShowDeletePopup(false);
        }
    };

    const deleteObject = async () => {
        try {
            await Delete(apiUrlDelete, item.id);
        } catch (error) {
            console.error('Error during deletion:', error);
        }
    };

    if (!item) {
        return <div><p>Loading...</p></div>;
    }

    return (
        <div className={`delete-popup delete-popup--background flex--column justify-content-center align-items--center align-text--center`}>
            <div className="delete-popup-content flex--column justify-content-center align-text--center">
                <h3>Du sletter nå {item?.properties?.name || item?.name}</h3>
                {item?.properties?.type === 'Rør' && (
                    <>
                        {(item?.properties?.cables?.length > 0 || item?.properties?.pipes?.length > 0) && (
                            <>
                                <p className="align-text--left">Sletter du dette objektet sletter du også disse objektene:</p>
                                <div className="delete-popup--relationships">
                                    {item?.properties?.cables?.length > 0 && item.properties.cables.map((cableId, cableIndex) => (
                                        <p key={cableIndex}>{GetConnectionName(connectionData, cableId)} (Kabel)</p>
                                    ))}
                                    {item?.properties?.pipes?.length > 0 && item.properties.pipes.map((pipeId, pipeIndex) => (
                                        <p key={pipeIndex}>{GetConnectionName(connectionData, pipeId)} (Rør)</p>
                                    ))}
                                </div>
                            </>
                        )}
                    </>
                )}
                <p>Er du sikker på at du vil slette objektet?</p>
                <div className="flex--row justify-content-center">
                    <button
                        className="btn btn-delete-white-bg"
                        onClick={() => confirmDelete(item.id)}
                    >
                        Slett
                    </button>
                    <button
                        className="btn btn-primary-white-bg"
                        onClick={() => setShowDeletePopup(false)}
                    >
                        Avbryt
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeletePopup;

