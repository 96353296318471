const PolyRenderFilter = (layerFilters, item) => {
    if (item.properties.type === 'Borettslag') {
        if (!layerFilters.homeownerAssocs.operational && item.properties.status === "I drift") {
            return false;
        } 
        if (!layerFilters.homeownerAssocs.completed && item.properties.status === "Ferdig stilt") {
            return false;
        } 
        if (!layerFilters.homeownerAssocs.beingBuilt && item.properties.status === "Bygges") {
            return false;
        } 
        if (!layerFilters.homeownerAssocs.inPlanning && item.properties.status === "Under planlegging") {
            return false;
        } 
        if (!layerFilters.homeownerAssocs.unconnectedUnits && !(item.properties.portConnections === item.properties.apartmentCount)){ 
            return false;
        }
        if (!layerFilters.homeownerAssocs.allConnectedUnits && item.properties.portConnections === item.properties.apartmentCount){ 
            return false;
        }
        return true;
    }
    else {
        return true;
    }
}

export default PolyRenderFilter;