const GetById = async (endpoint, id) => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(endpoint + "/" + id, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};

export default GetById;
