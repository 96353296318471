import React, { useEffect, useState } from 'react';
import InfoMessages from "../../messagesAndPopups/InfoMessages";
import RegexAddress from "../../../functions/formats-and-regex/RegexAddress";
import GetCoordinatesFromAddress from '../../../functions/apiCalls/osm/GetCoordinatesFromAddress';
import NewHomeownerAssocInputs from './Inputs/HomeownerAssocInputs';
import HandleInputChange from "../../../functions/inputs/HandleInputChange";

const NewHomeownerAssocForm = ({ onSubmit, isAddingHomeownerAssoc, setIsAddingHomeownerAssoc, drawnCoordinates, setDrawnCoordinates }) => {
    const initialFormData = {
        orgNumber: '',
        name: '',
        address: '',
        status: 'I drift',
        apartmentCount: 1,
        notes: '',
        netadminData: false
    };
    const [formData, setFormData] = useState(initialFormData);
    const [messageData, setMessageData] = useState(null);
    const [netadminHomeownerAssocs, setNetadminHomeownerAssocs] = useState([]);

    const getCoordinates = async (address) => {
        try {
            if (!address) {
                throw new Error('Address is empty');
            }
            const response = await GetCoordinatesFromAddress(address);
            if (response && response.length > 0) {
                const location = response[0];
                return [parseFloat(location.lat), parseFloat(location.lon)];
            } else {
                throw new Error('No coordinates found for the address');
            }
        } catch (error) {
            console.error('Error fetching geocoding data:', error.message);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { orgNumber, name, status, address, apartmentCount, notes, netadminData } = formData;
        try {
            if (!RegexAddress(address)) {
                setMessageData({
                    type: 'warning',
                    message: 'Ugyldig adresseformat. Vennligst skriv inn i formatet "Gateadresse Nummer, Postnummer Sted".'
                });
                return;
            }
            if (apartmentCount < 1) {
                setMessageData({
                    type: 'warning',
                    message: 'Må ha minst en leilighet!".'
                });
                return;
            }
            if (orgNumber.length !== 9) {
                setMessageData({
                    type: 'warning',
                    message: 'Org Nummer må være 9 siffer langt".'
                });
                return;
            }
            if (!drawnCoordinates.length && !isAddingHomeownerAssoc) {
                const coordinates = await getCoordinates(address);
                if (name && coordinates) {
                    const homeownerAssocData = {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [[coordinates]],
                        },
                        properties: {
                            orgNumber: orgNumber,
                            type: 'Homeowner Association',
                            name: name,
                            address: address,
                            status: status,
                            apartmentCount: apartmentCount,
                            notes: notes,
                            netadminData: netadminData
                        },
                    };
                    onSubmit(homeownerAssocData);
                    setFormData(initialFormData);
                } else {
                    setMessageData({
                        type: 'warning',
                        message: 'Du må fylle inn alle felt!'
                    });
                }
            } else {
                if (drawnCoordinates.length >= 2 && address) {
                    const homeownerAssocData = {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [drawnCoordinates],
                        },
                        properties: {
                            orgNumber: orgNumber,
                            type: 'Homeowner Association',
                            name: name,
                            address: address,
                            status: status,
                            apartmentCount: apartmentCount,
                            notes: notes
                        },
                    };
                    onSubmit(homeownerAssocData);
                    setFormData(initialFormData);
                } else {
                    setMessageData({
                        type: 'warning',
                        message: 'Du må fylle inn alle felt og tegne inn minst 2 koordinater!'
                    });
                }
            }
        } catch (error) {
            alert('Failed to retrieve coordinates for the address. Please try again.');
        }
    };

    const handleToggleDrawing = () => {
        setIsAddingHomeownerAssoc(prev => !prev);
    };

    useEffect(() => {
        if (isAddingHomeownerAssoc) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [isAddingHomeownerAssoc]);

    useEffect(() => {
        if (!isAddingHomeownerAssoc) {
            setDrawnCoordinates([]);
        }
    }, [isAddingHomeownerAssoc, setDrawnCoordinates]);

    return (
        <div className="map--add-form flex--column justify-content-center p-30px">
            <div className="map--add-form-header flex--row justify-content-sb">
                <h3>Borettslag</h3>
                <button
                    className={`btn btn-secondary-white-bg flex--row ${isAddingHomeownerAssoc ? 'active' : ''}`}
                    onClick={handleToggleDrawing}
                >
                    {isAddingHomeownerAssoc ? (
                        <>
                            Slett Areal <svg className="icon--green-edit"/>
                        </>
                    ) : (
                        <>
                            Marker Areal <svg className="icon--white-edit"/>
                        </>
                    )}
                </button>
                <div className="helping-box helping-box--draw-poly">
                    {isAddingHomeownerAssoc ? (
                        <p>Klikk for å slette markeringene gjort på kartet.</p>
                    ) : (
                        <p>Klikk for å tegne borettslaget på kartet. Høyreklikk for å avslutte tegningen uten å slette. Om ingenting tegnes vil koordinatet bli hentet fra adressen.</p>
                    )}
                </div>
            </div>

            <form onSubmit={handleSubmit} className="flex--column justify-content-sb align-items--center">
                <div className="map--add-form-message">
                    {messageData && (
                        <InfoMessages type={messageData.type} message={messageData.message}/>
                    )}
                </div>

                <NewHomeownerAssocInputs handleInputChange={HandleInputChange} formData={formData} setFormData={setFormData} netadminHomeownerAssocs={netadminHomeownerAssocs} setNetadminHomeownerAssocs={setNetadminHomeownerAssocs}/>

                <button
                    className="btn btn-secondary-white-bg"
                    type="submit"
                >
                    Opprett
                </button>
            </form>
        </div>
    );
};

export default NewHomeownerAssocForm;



