import React, {useEffect, useState} from "react";
import Update from "../../../../../functions/apiCalls/Update/Update";
import apiUrls from "../../../../../configs/api";
import statusOptions from "../../../../../assets/data/jsonFiles/General/statuses.json";
import cableTypes from "../../../../../assets/data/jsonFiles/Cables/cable-types.json";
import HandleNotification from "../../../../../functions/notifications/HandleNotification";

const InfoPageEditCableContents = ({cable, newName, setEditMode}) => {
    const [editedCable, setEditedCable] = useState({
        name: newName,
        status: cable.properties.status,
        cableType: cable.properties.cableType,
        notes: cable.properties.notes,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditedCable({ ...editedCable, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const updatedData = {
                name: newName === '' ? cable.properties.name : newName,
                status: editedCable.status,
                cableType: editedCable.cableType,
                notes: editedCable.notes
            };

            await Update(cable.id, apiUrls.put.updateCableInfo, updatedData);
            await HandleNotification(cable, 'Endret informasjonen til kabelen', cable.properties.name);
            setEditMode(false);
        } catch (error) {
            console.error('Failed to update data:', error);
        }
    };

    useEffect(() => {
        setEditedCable({ ...cable.properties });
    }, [cable]);

    return (
        <div className="info-page--content-data">
            <form className="info-page--edit-info flex--column" onSubmit={handleSubmit}>
                <label>Status</label>
                <select
                    name="status"
                    id="status"
                    value={editedCable.status}
                    onChange={handleChange}
                >

                    {statusOptions.statusOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <label>Type</label>
                <select
                    name="cableType"
                    id="cableType"
                    value={editedCable.cableType}
                    onChange={handleChange}
                >
                    {cableTypes.cableTypes.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <label>Notat</label>
                <input
                    type="text"
                    id="notes"
                    name="notes"
                    className="wide-input"
                    value={editedCable.notes}
                    onChange={handleChange}
                    placeholder="Viktig tilleggsinformasjon"
                />

                <div className="flex--row justify-content-center align-items--center">
                    <button
                        className="btn btn-secondary-white-bg"
                        type="submit"
                    >
                        Lagre Endringer
                    </button>
                </div>
            </form>
        </div>
    );
}
export default InfoPageEditCableContents;