export const calculateLineLength = (lineString) => {
    let totalDistance = 0;
    for (let i = 1; i < lineString.length; i++) {
        const lat1 = lineString[i - 1][1];
        const lon1 = lineString[i - 1][0];
        const lat2 = lineString[i][1];
        const lon2 = lineString[i][0];
        const distance = haversineDistance(lat1, lon1, lat2, lon2);
        totalDistance += distance;
    }
    return totalDistance;
};

export const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Earth radius in meters
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance;
};