import React, { useState } from 'react';
import SeeAllUsers from "../containers/SeeAllUsers";
import NewUserForm from "../forms/NewUserForm";
import EditUsersForm from "../forms/EditUsersForm";

const AdminDisplay = () => {
    const [activeForm, setActiveForm] = useState('allUsers');

    const toggleForm = (form) => {
        setActiveForm(prevForm => (prevForm === form ? 'allUsers' : form));
    };

    return (
        <div className="profile--display-page flex--column">
            <div className="flex--row align-items--center justify-content-sb profile--display-header profile--admin-nav">
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'allUsers' ? 'active' : ''}`}
                    onClick={() => toggleForm('allUsers')}
                >
                    Se Alle Brukere
                </button>
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'addNewUser' ? 'active' : ''}`}
                    onClick={() => toggleForm('addNewUser')}
                >
                    Opprett Ny bruker
                </button>
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'editUsers' ? 'active' : ''}`}
                    onClick={() => toggleForm('editUsers')}
                >
                    Endre Bruker Info
                </button>
            </div>
            <div>
                {activeForm === 'allUsers' && <SeeAllUsers/>}
                {activeForm === 'addNewUser' && <NewUserForm/>}
                {activeForm === 'editUsers' && <EditUsersForm/>}
            </div>
        </div>
    );
};

export default AdminDisplay;