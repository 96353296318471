import React, { useEffect, useState } from 'react';
import apiUrls from "../../configs/api";
import FormatDate from "../../functions/formats-and-regex/formatDate";
import firstLetterUpper from "../../functions/formats-and-regex/FirstUpperCase";
import GetAll from "../../functions/apiCalls/Get/GetAll";
import GetById from "../../functions/apiCalls/Get/GetById";

const ViewFeedback = () => {
    const [feedbacks, setFeedbackData] = useState([]);
    const [users, setUsersData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const feedbackData = await GetAll(apiUrls.developer.feedback);
            if (feedbackData) {
                setFeedbackData(feedbackData);
                const userIds = feedbackData.map(feedback => feedback.userId);
                const users = await Promise.all(userIds.map(userId => GetById(apiUrls.get.users, userId)));
                setUsersData(users);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="profile--developer-see-feedback">
            <div className="profile--developer-see-feedback-header flex--row">
                <p>Type</p>
                <p>Bruker</p>
                <p>Melding</p>
                <p>Versjon</p>
                <p>Dato</p>
            </div>

            <div className="profile--developer-see-feedback-data flex--column">
                {feedbacks.length > 0 ? (
                        feedbacks.slice().reverse().map((feedback, index) => {
                            const user = users.find(user => user && user.id === feedback.userId);
                            return (
                                <div key={index} className={`feedback ${feedback.type} br flex--row align-items--center`}>
                                    <p>{firstLetterUpper(feedback.type)}</p>
                                    {user && <p>{user.username}</p>}
                                    <p>{feedback.message}</p>
                                    <p>{feedback.version}</p>
                                    <p>{FormatDate(feedback.date)}</p>
                                </div>
                            );
                        }))
                    : (
                        <div className="feedback br flex--row justify-content-sb">
                            <p className='feedback-none'>Ingen tilbakemeldinger foreløping</p>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default ViewFeedback;
