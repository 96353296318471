import { useEffect } from 'react';
import statusOptions from '../../../../assets/data/jsonFiles/General/statuses.json';
import pipeColors from '../../../../assets/data/jsonFiles/Pipes/pipe-colors.json';
import pipeSizes from '../../../../assets/data/jsonFiles/Pipes/pipe-sizes.json';
import SelectWithData from '../../../inputsSelectsCheckboxes/SelectWithData';

const NewPipeInputs = ({ handleInputChange, formData, pipesData, handlePipeChange, setFormData }) => {
    const getPipesOptions = () => {
        return pipesData.map(pipe => ({
            value: pipe.id,
            label: pipe.properties.name,
            full: pipe.properties.filled,
            tempClosed: pipe.properties.status === "Midlertidig stengt/Under vedlikehold"
        }));
    };

    useEffect(() => {
        if (formData.pipeInPipe === 'Ja' && pipesData.length > 0 && !formData.selectedPipeId) {
            const firstUnfilledOrTempClosedPipe = pipesData.find(pipe =>
                !(pipe.properties.filled || pipe.properties.status === "Midlertidig stengt/Under vedlikehold")
            );
            if (firstUnfilledOrTempClosedPipe) {
                handleInputChange('selectedPipeId', firstUnfilledOrTempClosedPipe.id, setFormData);
            } else {
                handleInputChange('pipeInPipe', 'Nei', setFormData);
            }
        }
    }, [formData.pipeInPipe, pipesData, formData.selectedPipeId]);

    return (
        <div className="map--add-form-inputs flex--column">
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>Navn</label>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        placeholder='Røret'
                        value={formData.name}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    />
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Status</label>
                    <select
                        name="status"
                        id="status"
                        value={formData.status}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    >
                        {statusOptions.statusOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>Farge</label>
                    <select
                        name="color"
                        id="color"
                        value={formData.color}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    >
                        {pipeColors.pipeColors.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Størrelse</label>
                    <select
                        name="size"
                        id="size"
                        value={formData.size}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    >
                        {pipeSizes.pipeSizes.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>I Rør?</label>
                    <select
                        name="pipeInPipe"
                        id="pipeInPipe"
                        value={formData.pipeInPipe}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    >
                        <option value="Nei">Nei</option>
                        <option value="Ja">Ja</option>
                    </select>
                </div>
                <div className='flex--column map--add-form-input-container'>
                    {formData.pipeInPipe === "Ja" && (
                        <>
                            <label>Velg Rør</label>
                            <SelectWithData
                                data={getPipesOptions()}
                                name="selectedPipeId"
                                id="selectedPipeId"
                                value={formData.selectedPipeId}
                                onChange={handlePipeChange}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column whole-row'>
                    <label>Notater</label>
                    <input
                        type='text'
                        id='notes'
                        name='notes'
                        placeholder='Viktig tilleggsinformasjon'
                        value={formData.notes}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    />
                </div>
            </div>
        </div>
    );
}
export default NewPipeInputs;