import React, { useEffect, useState } from 'react';
import statusOptions from '../../../../assets/data/jsonFiles/General/statuses.json';
import cableTypes from '../../../../assets/data/jsonFiles/Cables/cable-types.json';
import getAllNodesPorts from '../../../../functions/extractors/getAllNodesPorts';
import getAvailablePorts from '../../../../functions/extractors/getAvailablePorts';
import SelectWithData from '../../../inputsSelectsCheckboxes/SelectWithData';
import InfoMessages from "../../../messagesAndPopups/InfoMessages";

const NewCableInputs = ({ handleInputChange, handlePipeChange, currentGroupIndex, pipesData, formData, handleNodeChange, nodesData, setFormData }) => {
    const [ports, setPorts] = useState([]);

    useEffect(() => {
        if (formData.choosePortAuto === false && !formData.selectedNode && nodesData) {
            handleNodeChange('selectedNode', nodesData[0].id);
        }
    }, [nodesData, formData.choosePortAuto]);

    useEffect(() => {
    if (formData.selectedNode) {
        const fetchPorts = async () => {
            const portsData = await getAllNodesPorts(formData.selectedNode.id);
            setPorts(portsData);
        };
        fetchPorts();
        }
    }, [formData.selectedNode]);

    const getPipesOptions = () => {
        return pipesData.map(pipe => ({
            value: pipe.id,
            label: pipe.properties.name,
            full: pipe.properties.filled,
            tempClosed: pipe.properties.status === "Midlertidig stengt/Under vedlikehold"
        }));
    };

    const getNodesOptions = () => {
        return nodesData.map(node => ({
            value: node.id,
            label: node.name,
            full: getAvailablePorts(node) === 0,
            tempClosed: node.status === "Midlertidig stengt/Under vedlikehold"
        }));
    };

    return (
        <div className="map--add-form-inputs flex--column">
            {currentGroupIndex === 0 && (
                <>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Navn</label>
                            <input
                                type='text'
                                id='name'
                                name='name'
                                placeholder='Kabelnavnet'
                                value={formData.name}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Kabel Type</label>
                            <SelectWithData
                                data={cableTypes.cableTypes}
                                name="cableType"
                                id="cableType"
                                value={formData.cableType}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Status</label>
                            <SelectWithData
                                data={statusOptions.statusOptions}
                                name="status"
                                id="status"
                                value={formData.status}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column whole-row'>
                            <label>Notater</label>
                            <input
                                type='text'
                                id='notes'
                                name='notes'
                                placeholder='Viktig tilleggsinformasjon'
                                value={formData.notes}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                </>
            )}

            {currentGroupIndex === 1 && (
                <>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className="flex--column map--add-form-input-container">
                            <label>I Rør</label>
                            <SelectWithData
                                data={getPipesOptions()}
                                name="selectedPipe"
                                id="selectedPipe"
                                value={formData.selectedPipe}
                                onChange={handlePipeChange}
                                extraOption={true}
                                extraOptionLabel="Ikke i rør"
                            />
                        </div>
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        {getNodesOptions().length > 0 && (
                            <>
                                <div className='flex--column map--add-form-input-container'>
                                    <label>
                                        Velg Node og Port automatisk
                                    </label>
                                    <div className='checkbox flex--column justify-content-center'>
                                        <input
                                            name='choosePortAuto'
                                            id='choosePortAuto'
                                            type='checkbox'
                                            checked={formData.choosePortAuto}
                                            onChange={(e) => handleInputChange(e.target.name, e.target.checked, setFormData)}
                                            disabled={!getNodesOptions().length > 0}
                                        />
                                    </div>
                                </div>
                                <div className='flex--column map--add-form-input-container message'>
                                    <InfoMessages type='info'
                                                  message='Husk å tegne kabelen til den valgte noden. La stå huket av om kabelen ikke skal kobles til noen noder.'/>
                                </div>
                            </>
                        )}
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        {!formData.choosePortAuto && (
                            <>
                                <div className="flex--column map--add-form-input-container">
                                    <label>Velg Node</label>
                                    <SelectWithData
                                        data={getNodesOptions()}
                                        name="selectedNode"
                                        id="selectedNode"
                                        value={formData.selectedNode ? formData.selectedNode.id : ''}
                                        onChange={(e) => handleNodeChange(e.target.name, e.target.value)}
                                    />
                                </div>
                                <div className='flex--column map--add-form-input-container'>
                                    <label>Velg Port</label>
                                    <SelectWithData
                                        data={ports}
                                        name="portNumber"
                                        id="portNumber"
                                        value={formData.portNumber}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default NewCableInputs;


