import { useParams } from 'react-router-dom';
import HeaderLoggedIn from "../../../components/headers/headerLoggedIn";
import React, {useEffect, useState} from "react";
import InfoPageHeader from "../../../components/headers/InfoPageHeader";
import GetById from "../../../functions/apiCalls/Get/GetById";
import MiniMap from "../../../components/maps/MiniMap";
import apiUrls from "../../../configs/api";
import InfoPagePipeContents from "../../../components/containers/infoPage/InfoPageContents/InfoPagePipeContents";
import styles from "../../../components/iconsAndStyles/Styles";
import InfoPageEditHeader from "../../../components/inputsSelectsCheckboxes/InfoPageEditHeader";
import InfoPageEditPipeContents from "../../../components/containers/infoPage/InfoPageContents/Edit/InfoPageEditPipeContents";
import Update from "../../../functions/apiCalls/Update/Update";
import HeaderButtons from "../../../components/buttons/HeaderButtons";
import To404 from "../../../functions/redirects/To404";
import HandleNotification from "../../../functions/notifications/HandleNotification";
import GetUserType from '../../../functions/apiCalls/Get/GetUserType';

const PipeInfoPage = () => {
    const { id } = useParams();
    const userId = sessionStorage.getItem('userId');
    const [pipe, setPipeData] = useState(null);
    const pipeStyle = styles['pipe'];
    const [editLock, setEditLock] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [newName, setNewName] = useState('');
    const [filledStatus, setFilledStatus] = useState(false);
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const fetchUserType = async () => {
            const userType = await GetUserType();
            setUserType(userType);
        };
        fetchUserType();
    }, []);

    let buttonDisabled = pipe && pipe.properties.editLock && !(userId === pipe.properties.editorLockUserId || userType === "Admin" || userType === "Utvikler");

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const data = await GetById(apiUrls.get.pipes, id);
                    if (data) {
                        setPipeData(data);
                        setFilledStatus(data.properties.filled)
                        setEditLock(data.properties.editLock);
                    }
                    else {
                        To404();
                    }
                }
            } catch (error) {
                console.error('Error fetching pipe data:', error);
            }
        };
        fetchData();
    }, [id, editMode, setFilledStatus, editLock]);

    const handleSetAsFilled = async () => {
        const updatedData = {
            filled: !filledStatus
        };
        try {
            await Update(id, apiUrls.put.updatePipeFilledStatus, updatedData);
            if (!filledStatus) {
                await HandleNotification(pipe, "Markerte som fullt: ",pipe.properties.name);
            } else {
                await HandleNotification(pipe, "Fjernet markering som fullt: ",pipe.properties.name);
            }
            setFilledStatus(prevFilledStatus => !prevFilledStatus);
        } catch (error) {
            console.error('Error updating filled status:', error);
        }
    }

    useEffect(() => {
        document.body.classList.add("overflow-hidden");
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, []);

    return (
        <div>
            {pipe && (
                <>
                    <HeaderLoggedIn/>
                    <div className="info-page flex--row">
                        <div className="info-page--map">
                            <MiniMap marker={pipe} icon="pipe" style={pipeStyle} zoom={16}/>
                        </div>
                        <div className="info-page--content flex--column w-75vw">
                            <div className="info-page--content-header flex--row justify-content-sb align-items--center">
                                {pipe.properties && (
                                    !editMode ? (
                                        <InfoPageHeader
                                            name={pipe.properties.name}
                                            icon="pipe"
                                        />
                                    ) : (
                                        <InfoPageEditHeader
                                            name={pipe.properties.name}
                                            icon="pipe"
                                            setNewName={setNewName}
                                        />
                                    )
                                )}
                                {userType !== 'Read Only' && (
                                    <div className="flex--row">
                                        {!editMode && (
                                            <button
                                                onClick={handleSetAsFilled}
                                                className={`btn btn-primary-white-bg ${filledStatus ? 'active' : ''} ${buttonDisabled ? 'disabled' : ''} flex--row justify-content-center align-items--center m-r-10px`}
                                                disabled={buttonDisabled}
                                            >
                                                {!filledStatus ? 'Marker som fullt' : 'Marker som tilgjengelig'}
                                            </button>
                                        )}
                                        <HeaderButtons
                                            editMode={editMode}
                                            setEditMode={setEditMode}
                                            editLock={editLock}
                                            setEditLock={setEditLock}
                                            userId={userId}
                                            userType={userType}
                                            item={pipe}
                                            apiUrlLock={apiUrls.put.updateEditLockPipe}
                                            apiUrlDelete={apiUrls.delete.deletePipe}
                                        />
                                    </div>
                                )}
                            </div>
                            {!editMode ? (
                                <InfoPagePipeContents pipe={pipe} filledStatus={filledStatus} userType={userType}/>
                            ) : (
                                <InfoPageEditPipeContents pipe={pipe} newName={newName} setEditMode={setEditMode}/>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default PipeInfoPage;