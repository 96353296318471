const FindIfFullNode = (node) => {
    try {
        if (node.switches && node.switches.length > 0) {
            let allPorts = 0;
            let takenPorts = 0;

            for (const switchObj of node.switches) {
                if (switchObj.ports && switchObj.ports.length > 0) {
                    allPorts += switchObj.ports.length;
                    for (const port of switchObj.ports) {
                        if (port.connectedEntityId !== null) {
                            takenPorts++;
                        }
                    }
                }
            }
            const portsWithoutConnection = allPorts - takenPorts;
            return portsWithoutConnection === 0;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error finding node:', error);
        return false;
    }
}
export default FindIfFullNode;