import React, {useEffect, useState} from "react";
import Update from "../../../../../functions/apiCalls/Update/Update";
import apiUrls from "../../../../../configs/api";
import statusOptions from "../../../../../assets/data/jsonFiles/General/statuses.json";
import kumTypes from "../../../../../assets/data/jsonFiles/Kummer/kum-types.json";
import spliceEnclosureTypes from "../../../../../assets/data/jsonFiles/Kummer/splice-enclosure-types.json";
import HandleNotification from "../../../../../functions/notifications/HandleNotification";

const InfoPageEditKumContents = ({kum, newName, setEditMode}) => {
    const [editedKum, setEditedKum] = useState({
        name: newName,
        status: kum.status,
        kumType: kum.kumType,
        spliceEnclosure: kum.spliceEnclosure,
        notes: kum.notes ? kum.notes : ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditedKum({ ...editedKum, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updatedData = {
                name: newName === '' ? kum.name : newName,
                status: editedKum.status,
                kumType: editedKum.kumType,
                spliceEnclosure: editedKum.spliceEnclosure,
                notes: editedKum.notes
            };

            await Update(kum.id, apiUrls.put.updateKumInfo, updatedData);
            await HandleNotification(kum, 'Endret informasjonen til kummen', kum.name);
            setEditMode(false);
        } catch (error) {
            console.error('Failed to update data:', error);
        }
    };


    useEffect(() => {
        setEditedKum({ ...kum });
    }, [kum]);

    return (
        <div className="info-page--content-data">
            <form className="info-page--edit-info flex--column" onSubmit={handleSubmit}>
                <label>Status</label>
                <select
                    name="status"
                    id="status"
                    value={editedKum.status}
                    onChange={handleChange}
                >

                    {statusOptions.statusOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <label>Type</label>
                <select
                    name="kumType"
                    id="kumType"
                    value={editedKum.kumType}
                    onChange={handleChange}
                >

                    {kumTypes.kumTypes.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <label>Skjøte</label>
                <select
                    name="spliceEnclosure"
                    id="spliceEnclosure"
                    value={editedKum.spliceEnclosure}
                    onChange={handleChange}
                >
                    {spliceEnclosureTypes.spliceEnclosureTypes.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <label>Notat</label>
                <input
                    className="wide-input"
                    type="text"
                    id="notes"
                    name="notes"
                    value={editedKum.notes}
                    onChange={handleChange}
                    placeholder="Viktig tilleggsinformasjon"
                />

                <div className="flex--row align-items--center justify-content-center">
                    <button
                        className="btn btn-secondary-white-bg"
                        type="submit"
                    >
                        Lagre Endringer
                    </button>
                </div>
            </form>

        </div>
    );
}
export default InfoPageEditKumContents;