import React, {useEffect} from "react";

const FullScreenImage = ({
    images,
    handleFullScreen,
    displayedImageIndex,
    setDisplayedImageIndex,
    imageRef,
    DefaultImage,
    buttonDisabled,
    handleFileUpload,
    handleImageDelete,
    userType
}) => {

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'ArrowRight' && displayedImageIndex < images.length - 1) {
                setDisplayedImageIndex((prevIndex) => prevIndex + 1);
            } else if (event.key === 'ArrowLeft' && displayedImageIndex > 0) {
                setDisplayedImageIndex((prevIndex) => prevIndex - 1);
            }
            else if (event.key === 'Escape') {
                handleFullScreen();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [displayedImageIndex, images.length, setDisplayedImageIndex]);

    return (
        <div className="info-page--files-fullscreen flex--column justify-content-center align-items--center">
            <div className="info-page--files-fullscreen-container">
                {images.length > 0 ? (
                    <img
                        ref={imageRef}
                        src={`data:image/jpeg;base64,${images[displayedImageIndex].imageData}`}
                        alt="Bilde av kum"
                    />
                ) : (
                    <img ref={imageRef} src={DefaultImage} alt="Default Bilde"/>
                )}
                <div className="info-page--files-fullscreen-buttons br justify-content-sb align-items--center">
                    {images.length > 0 && (
                        <svg
                            className={`icon--blue-chevron-l ${displayedImageIndex === 0 ? "disabled" : ""}`}
                            onClick={displayedImageIndex === 0 ? null : () => setDisplayedImageIndex((prevIndex) => (prevIndex - 1) % images.length)}
                        />
                    )}

                    {userType !== "Read Only" && (
                        <div className="info-page--files-fullscreen-update-buttons flex--row align-items--center justify-content-sb">
                            <label htmlFor="file-upload" className={`image-add`}>
                                <svg
                                    className={`icon--plus-blue ${buttonDisabled ? "disabled" : ""} flex--column align-items--center`}
                                />
                                <input
                                    id="file-upload"
                                    className="m-s-auto"
                                    type="file"
                                    onChange={buttonDisabled ? null : handleFileUpload}
                                    accept="image/*"
                                    disabled={buttonDisabled}
                                />
                            </label>

                            <svg
                                className={`icon--blue-shrink`}
                                onClick={() => handleFullScreen()}
                            />
                            
                            {images.length > 0 && (
                                <label htmlFor="file-delete" className="image-delete">
                                    <svg
                                        className={`icon--trash-blue ${buttonDisabled ? "disabled" : ""} flex--column align-items--center`}
                                        onClick={buttonDisabled ? null : () => handleImageDelete(displayedImageIndex, images[displayedImageIndex].id)}
                                    />
                                </label>
                            )}
                        </div>
                    )}

                    {images.length > 0 && (
                        <svg
                            className={`icon--blue-chevron-r ${displayedImageIndex === images.length - 1 ? "disabled" : ""}`}
                            onClick={displayedImageIndex === images.length - 1 ? null : () => setDisplayedImageIndex((prevIndex) => (prevIndex + 1) % images.length)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default FullScreenImage;

