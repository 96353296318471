import React, { useEffect, useState } from 'react';

const VersionInfo = () => {
    const [version, setVersion] = useState('');

    useEffect(() => {
        fetch('/version.txt')
            .then(response => response.text())
            .then(versionNumber => setVersion(versionNumber))
            .catch(error => {
                console.error('Error fetching version number:', error);
                setVersion('Unknown');
            });
    }, []);

    return <span>{version}</span>;
};

export default VersionInfo;
