import React, {useEffect, useState} from 'react';
import apiUrls from "../../../configs/api";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import PopUpButtons from "./PopUpButtons";

const PopupHomeownerAssoc = ({item, handleUpdateCoordinates }) => {
    const [nodes, setNodesData] = useState([]);
    const [itemSvg, setItemSvg] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (item && item.properties && item.properties.nodes && item.properties.nodes.length > 0) {
                    const homeassocNodesIds = item.properties.nodes.map(homeassocNode => homeassocNode.pointId);
                    const allNodes = await GetAll(apiUrls.get.nodes);
                    const filteredNodes = allNodes.filter(node =>
                        homeassocNodesIds.includes(node.id)
                    );
                    setNodesData(filteredNodes);
                }
            } catch (error) {
                console.error('Error fetching pipe data:', error);
            }
        };
        fetchData();
        setItemSvg(item.properties.netadminData ? 'icon--homeownerassoc-netadmin' : 'icon--homeownerassoc');
    }, [item]);

    return (
        <>
            {item && (
                <div className="flex--column justify-content-center map--popup">
                    <div className="flex--row align-items--center map-popup-header">
                        <svg className={itemSvg}/>
                        <h4>{item.properties.name}</h4>
                    </div>

                    <div className="flex--row justify-content-sb map--popup-body">
                        <div className="flex--column justify-content-center map--popup-info">
                            <div className="flex--row long">
                                <p><b>Status:</b></p>
                                <p>{' '}&nbsp;{item.properties.status}</p>
                            </div>
                            <div className="flex--column tall">
                                <p><b>Antall Leiligheter:</b></p>
                                <p>{item.properties.apartmentCount ?? 0}</p>
                            </div>
                            <div className="flex--column tall">
                            <p><b>Adresse:</b></p>
                                <p>{item.properties.address}</p>
                            </div>
                            <div className="flex--column tall">
                                <p><b>Noder Tilkoblet:</b></p>
                                <div className="flex--row map--popup-list">
                                    {nodes.length > 0 ?
                                    nodes.map((node, index) => (
                                        <a href={`/node/${node.id}`} key={index}>{node.name}</a>
                                    )) : (
                                        <p>Ingen</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <PopUpButtons
                            item={item}
                            type="poly"
                            endpoint="borettslag"
                            handleUpdateCoordinates={handleUpdateCoordinates}
                        />
                    </div>
                </div>
            )}
        </>

    );
};

export default PopupHomeownerAssoc;