import React, { useState, useEffect } from "react";
import axios from "axios";
import { debounce } from 'lodash';

const SearchForAddressInput = ({ setMapCenter, setIsAddressSearch, setZoom }) => {
    const [address, setAddress] = useState('');
    const [matchingAddresses, setMatchingAddresses] = useState([]);

    const fetchAddresses = async (inputValue) => {
        if (inputValue.trim() === '') {
            setMatchingAddresses([]);
            return;
        }

        try {
            const response = await axios.get(
                `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(inputValue)}&countrycodes=no&limit=100`
            );
            const results = response.data;

            if (results.length > 0) {
                const numberRegex = /\d+/;
                const hasInputNumber = numberRegex.test(inputValue);
                const formattedResults = results.map((result) => {
                    const resultParts = result.display_name.split(',');
                    let formattedAddress = '';
                    if (hasInputNumber) {
                        // If the input has a number
                        formattedAddress = [
                            resultParts[0]?.trim(),
                            resultParts[1]?.trim(),
                            resultParts[4]?.trim()
                        ].filter(Boolean).join(', ');
                    } else {
                        // If no number
                        formattedAddress = [
                            resultParts[0]?.trim(),
                            resultParts[3]?.trim()
                        ].filter(Boolean).join(', ');
                    }
                    return {
                        displayName: formattedAddress,
                        lat: result.lat,
                        lon: result.lon,
                        original: result.display_name
                    };
                });

                const addressCount = new Map();
                formattedResults.forEach((item) => {
                    const key = item.displayName.toLowerCase();
                    addressCount.set(key, (addressCount.get(key) || 0) + 1);
                });

                const updatedResults = formattedResults.map((item) => {
                    const resultParts = item.original.split(',');
                    const key = item.displayName.toLowerCase();

                    if (addressCount.get(key) > 1) {
                        if (hasInputNumber) {
                            item.displayName = [
                                resultParts[0]?.trim(),  // house number
                                resultParts[1]?.trim(),  // street
                                resultParts[2]?.trim(),  // town/place
                                resultParts[4]?.trim()   // city
                            ].filter(Boolean).join(', ');
                        } else {
                            item.displayName = [
                                resultParts[0]?.trim(),  // street
                                resultParts[1]?.trim(),  // town/place
                                resultParts[3]?.trim()   // city
                            ].filter(Boolean).join(', ');
                        }
                    }
                    return item;
                });
                setMatchingAddresses(updatedResults);
            } else {
                setMatchingAddresses([]);
            }
        } catch (error) {
            console.error("Error fetching matching addresses:", error);
            setMatchingAddresses([]);
        }
    };


    const debouncedFetchAddresses = debounce(fetchAddresses, 500);

    useEffect(() => {
        debouncedFetchAddresses(address);
        return () => {
            debouncedFetchAddresses.cancel();
        };
    }, [address]);

    const handleInputChange = (e) => {
        setAddress(e.target.value);
    };

    const handleSelectAddress = (lat, lon) => {
        setMapCenter([parseFloat(lat), parseFloat(lon)]);
        setZoom(18);
        setIsAddressSearch(true);
        setMatchingAddresses([]);
    };

    return (
        <div className="map--search-address">
            <div className="input flex--row justify-content-sb align-items--center">
                <input
                    type="text"
                    placeholder="Freserveien 1, 0195"
                    className="search-input"
                    value={address}
                    onChange={handleInputChange}
                />
                <svg className="icon--green-search" onClick={() => handleInputChange({ target: { value: address } })} />
            </div>

            {matchingAddresses.length > 0 && (
                <div className="map--search-results">
                    <div className="results-more flex--row">
                        <p>Resultater:</p><p>{' '}&nbsp;{matchingAddresses.length}</p>
                    </div>
                    {matchingAddresses.map((addressResult, index) => (
                        <p key={index}
                           onClick={() => handleSelectAddress(addressResult.lat, addressResult.lon)}
                           className="search--result"
                        >
                            {addressResult.displayName}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchForAddressInput;






