import React from "react";

const ToggleButton = ({setSearch}) => {
    return (
        <label className="switch">
            <input type="checkbox" onChange={(e) => setSearch(e.target.checked)} />
            <span className="slider round"></span>
        </label>
    );
}
export default ToggleButton;