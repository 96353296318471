import React, { useState, useEffect } from 'react';
import { Polygon, Marker, useMapEvents } from 'react-leaflet';
import icons from "../../iconsAndStyles/Icons";

const DrawPolygons = ({ color, isAdding, snapMarker, isUpdating, handleDrawCoordinates }) => {
    const [coordinates, setCoordinates] = useState([]);
    const [currentCursorPosition, setCurrentCursorPosition] = useState(null);
    const [drawingStopped, setDrawingStopped] = useState(false);
    const [firstClick, setFirstClick] = useState(true);

    useMapEvents({
        mousemove: (e) => {
            if (isAdding && !drawingStopped) {
                setCurrentCursorPosition([e.latlng.lat, e.latlng.lng]);
            }
        },
        click: (e) => {
            if (isAdding && e.originalEvent.which === 1 && !drawingStopped) {
                if (!isUpdating) {
                    let clickedCoordinates = [e.latlng.lat, e.latlng.lng];
                    if (snapMarker) {
                        clickedCoordinates = [snapMarker.coordinates.latitude, snapMarker.coordinates.longitude];
                    }
                    setCoordinates(prevCoordinates => [...prevCoordinates, clickedCoordinates]);
                } else {
                    if (firstClick) {
                        setFirstClick(false);
                    } else {
                        let clickedCoordinates = [e.latlng.lat, e.latlng.lng];
                        if (snapMarker) {
                            clickedCoordinates = [snapMarker.coordinates.latitude, snapMarker.coordinates.longitude];
                        }
                        setCoordinates(prevCoordinates => [...prevCoordinates, clickedCoordinates]);
                        handleDrawCoordinates([...coordinates, clickedCoordinates]);
                    }
                }
            }
        },
        contextmenu: (e) => {
            if (isAdding) {
                setDrawingStopped(true);
            }
        },
    });

    useEffect(() => {
        if (!isAdding) {
            setCoordinates([]);
            setCurrentCursorPosition(null);
            setDrawingStopped(false);
        }
    }, [isAdding]);

    return (
        <>
            {coordinates.length > 0 && (
                <>
                    <Marker position={coordinates[0]} icon={icons["firstLastCoordinate"]} />
                    <Polygon
                        positions={coordinates}
                        color={color}
                    />

                    {isAdding && currentCursorPosition && !drawingStopped && (
                        <Polygon
                            positions={[...coordinates, currentCursorPosition]}
                            color={color}
                        />
                    )}
                </>
            )}
            {isAdding && currentCursorPosition && !drawingStopped && (
                <Marker position={currentCursorPosition} icon={icons["drawing"]} />
            )}
        </>
    );
};

export default DrawPolygons;
