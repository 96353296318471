import React, {useEffect} from "react";

const FullScreenPdf = ({ pdfs, displayedPdfIndex, setDisplayedPdfIndex, handleFileUpload, handleFullScreen, handleFileDeletion, buttonDisabled, userType, downloadPdf}) => {

    const displayPdf = (pdf) => {
        const binaryString = window.atob(pdf.fileData);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        return `${url}#toolbar=0&scrollbar=0&zoom=75`;
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'ArrowRight' && displayedPdfIndex < pdfs.length - 1) {
                setDisplayedPdfIndex((prevIndex) => prevIndex + 1);
            } else if (event.key === 'ArrowLeft' && displayedPdfIndex > 0) {
                setDisplayedPdfIndex((prevIndex) => prevIndex - 1);
            }
            else if (event.key === 'Escape') {
                handleFullScreen();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [displayedPdfIndex, setDisplayedPdfIndex, pdfs.length]);

    return (
        <div className="info-page--files-fullscreen flex--column justify-content-center align-items--center">
            <div className="info-page--files-fullscreen-container">
                <div className="pdf">
                    <embed
                        src={displayPdf(pdfs[displayedPdfIndex])}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                    />
                </div>

                <div className="info-page--files-fullscreen-buttons br justify-content-sb align-items--center">
                    {pdfs.length > 0 && (
                        <svg
                            className={`icon--blue-chevron-l ${displayedPdfIndex === 0 ? "disabled" : ""}`}
                            onClick={displayedPdfIndex === 0 ? null : () => setDisplayedPdfIndex((prevIndex) => (prevIndex - 1) % pdfs.length)}
                        />
                    )}
                        <div className="info-page--files-fullscreen-update-buttons flex--row align-items--center justify-content-sb">
                            {userType !== "Read Only" && (
                                <>
                                    <label htmlFor="pdf-upload">
                                        <svg
                                            className={`icon--plus-blue ${buttonDisabled ? "disabled" : ""} flex--column align-items--center`}
                                        />
                                        <input
                                            id="file-upload"
                                            type="file"
                                            onChange={handleFileUpload}
                                            disabled={buttonDisabled}
                                        />
                                    </label>
                                    {pdfs.length > 0 && (
                                        <label htmlFor="file-delete" className="image-delete">
                                            <svg
                                                className={`icon--trash-blue ${buttonDisabled ? "disabled" : ""} flex--column align-items--center`}
                                                onClick={buttonDisabled ? null : () => handleFileDeletion(displayedPdfIndex, pdfs[displayedPdfIndex].id)}
                                            />
                                        </label>
                                    )}
                                    <svg
                                        className={`icon--blue-shrink`}
                                        onClick={() => handleFullScreen()}
                                    />
                                </>
                            )}
                            {pdfs.length > 0 && (
                                <label htmlFor="pdf-download" className="image-dowload">
                                    <svg
                                        className={`icon--blue-download flex--column align-items--center`}
                                        onClick={() => downloadPdf(pdfs[displayedPdfIndex])}
                                    />
                                </label>
                            )}
                        </div>
                    {pdfs.length > 0 && (
                        <svg
                            className={`icon--blue-chevron-r ${displayedPdfIndex === pdfs.length - 1 ? "disabled" : ""}`}
                            onClick={displayedPdfIndex === pdfs.length - 1 ? null : () => setDisplayedPdfIndex((prevIndex) => (prevIndex + 1) % pdfs.length)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default FullScreenPdf;