import React, {useEffect, useState} from "react";
import GetAll from "../../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../../configs/api";
import GetById from "../../../../functions/apiCalls/Get/GetById";
import InfoPageLog from "../InfoPageContainers/InfoPageLog";
import InfoPageData from "../InfoPageContainers/InfoPageData";
import {calculateLineLength} from "../../../../functions/page-spesific/infoPage/getDistanceInMeters";
import InfoPageFiles from "../InfoPageContainers/InfoPageFiles";
import InfoPageConnections from "../InfoPageContainers/InfoPageConnections";

const InfoPageCableContents = ({cable, userType}) => {
    const [cableLength, setCableLength] = useState(0);
    const [kummer, setKummer] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [pipe, setPipe] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (cable && cable.properties && cable.properties.kummer && cable.properties.kummer.length > 0) {
                    const cableKumIds = cable.properties.kummer.map(cableKum => cableKum.pointId);
                    const allKummer = await GetAll(apiUrls.get.kummer);
                    const filteredKummer = allKummer.filter(kum =>
                        cableKumIds.includes(kum.id)
                    );
                    setKummer(filteredKummer);
                }

                if (cable && cable.properties && cable.properties.nodes && cable.properties.nodes.length > 0) {
                    const cableNodeIds = cable.properties.nodes.map(cableNode => cableNode.pointId);
                    const allNodes = await GetAll(apiUrls.get.nodes);
                    const filteredNodes = allNodes.filter(node =>
                        cableNodeIds.includes(node.id)
                    );
                    setNodes(filteredNodes);
                }
                if (cable && cable.properties && cable.properties.pipeId) {
                    const pipe = await GetById(apiUrls.get.pipes, cable.properties.pipeId);
                    setPipe(pipe);
                }
            } catch (error) {
                console.error('Error fetching cable data:', error);
            }
        };
        fetchData();
    }, [cable]);

    useEffect(() => {
        if (cable && cable.geometry && cable.geometry.coordinates && cable.geometry.coordinates.length > 0) {
            const length = calculateLineLength(cable.geometry.coordinates);
            setCableLength(length);
        }
    }, [cable]);

    return (
        <div className="info-page--content-data">
            <InfoPageLog item={cable}/>
            <div className="flex--row justify-content-sb">
                <InfoPageData
                    margin={false}
                    title="Info"
                    headers={["Status", "Type", "Lengde", "Start Koordinat", "Slutt Koordinat", "Notater"]}
                    data={[
                        cable.properties.status,
                        cable.properties.cableType,
                        `${cableLength.toFixed(0)} meter`,
                        `${cable.geometry.coordinates[0][1]}, ${cable.geometry.coordinates[0][0]}`,
                        `${cable.geometry.coordinates[cable.geometry.coordinates.length - 1][1]}, ${cable.geometry.coordinates[cable.geometry.coordinates.length - 1][0]}`,
                        cable.properties.notes ? cable.properties.notes : 'Ingen notater'
                    ]}
                />
                <InfoPageFiles
                    item={cable}
                    hasImages={false}
                    editLock={cable.properties.editLock}
                    editorLockUserId={cable.properties.editorLockUserId}
                    DefaultImage={null}
                    userType={userType}
                />
            </div>

            <InfoPageConnections
                item={cable}
                type="cable"
                headers={["I Røret", "Noder", "Tilkoblingspunkter"]}
                data={[
                    {array: pipe, message: "Denne kabelen er ikke i et rør" },
                    { array: nodes, message: "Kabel ikke koblet til noen porter"},
                    { array: kummer, message: "Ingen tilkoblingspunkter koblet til denne kabelen" }
                ]}
            />
        </div>
    );
}
export default InfoPageCableContents;