import React, {useEffect, useState} from "react";
import Update from "../../../../../functions/apiCalls/Update/Update";
import apiUrls from "../../../../../configs/api";
import nodeStatuses from "../../../../../assets/data/jsonFiles/Nodes/node_statuses.json";
import nodeTypes from "../../../../../assets/data/jsonFiles/Nodes/node-types.json";
import HandleNotification from "../../../../../functions/notifications/HandleNotification";

const InfoPageEditNodeContents = ({node, newName, setEditMode}) => {
    const userId = sessionStorage.getItem('userId');
    const [editedNode, setEditedNode] = useState({
        name: newName,
        status: node.status,
        nodeType: node.nodes,
        address: node.address,
        floor: node.floor,
        lastEditedById: userId,
        notes: node.notes ? node.notes : ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditedNode({ ...editedNode, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updatedData = {
                name: newName === '' ? node.name : newName,
                status: editedNode.status,
                nodeType: editedNode.nodeType,
                address: editedNode.address,
                floor: editedNode.floor,
                lastEditedById: userId,
                notes: editedNode.notes
            };

            await Update(node.id, apiUrls.put.updateNodeInfo, updatedData);
            await HandleNotification(node, 'Endret informasjonen til noden', node.name);
            setEditMode(false);
        } catch (error) {
            console.error('Failed to update data:', error);
        }
    };

    useEffect(() => {
        setEditedNode({ ...node });
    }, [node]);

    return (
        <div className="info-page--content-data">
            <form className="info-page--edit-info flex--column" onSubmit={handleSubmit}>
                <label>Status</label>
                <select
                    name="status"
                    id="status"
                    value={editedNode.status}
                    onChange={handleChange}
                >
                    {nodeStatuses.nodeStatusOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <label>Type</label>
                <select
                    name="nodeType"
                    id="nodeType"
                    value={editedNode.nodeType}
                    onChange={handleChange}
                >
                    {nodeTypes.nodeTypes.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <div className="flex--row justify-content-sb info-page--edit-info-row">
                    <div className="flex--column info-page--edit-info-row-input">
                        <label htmlFor="address">Adresse</label>
                        <input
                            type="text"
                            min={1}
                            id="address"
                            name="address"
                            value={editedNode.address}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex--column info-page--edit-info-row-input">
                        <label htmlFor="floor">Etasje</label>
                        <input
                            type="number"
                            id="floor"
                            name="floor"
                            value={editedNode.floor}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <label htmlFor="notes">Notat</label>
                <input
                    type="text"
                    min={1}
                    id="notes"
                    name="notes"
                    className="wide-input"
                    value={editedNode.notes}
                    onChange={handleChange}
                    placeholder="Viktig tilleggsinformasjon"
                />

                <div className="flex--row justify-content-center align-items--center">
                    <button
                        className="btn btn-secondary-white-bg"
                        type="submit"
                    >
                        Lagre Endringer
                    </button>
                </div>
            </form>

        </div>
    );
}
export default InfoPageEditNodeContents;