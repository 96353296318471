import React, { useEffect, useState } from 'react';
import apiUrls from "../../../configs/api";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import PopUpButtons from "./PopUpButtons";

const PopupPipes = ({ item, handleUpdateCoordinates }) => {
    const [cablesPipes, setCablesPipes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let combinedData = [];
                if (item?.properties?.cables?.length > 0) {
                    const pipeCableIds = item.properties.cables;
                    const allCables = await GetAll(apiUrls.get.cables);
                    const filteredCables = allCables.filter(cable => pipeCableIds.includes(cable.id));
                    combinedData = combinedData.concat(filteredCables);
                }
                if (item?.properties?.pipes?.length > 0) {
                    const pipePipeIds = item.properties.pipes;
                    const allPipes = await GetAll(apiUrls.get.pipes);
                    const filteredPipes = allPipes.filter(pipe => pipePipeIds.includes(pipe.id));
                    combinedData = combinedData.concat(filteredPipes);
                }
                setCablesPipes(combinedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [item]);

    return (
        <div className="flex--column justify-content-center map--popup">
            <div className="flex--row align-items--center map-popup-header">
                <svg className="icon--pipe" />
                <h4>{item.properties.name}</h4>
            </div>
            <div className="flex--row justify-content-sb map--popup-body">
                <div className="flex--column map--popup-info">
                    <div className="flex--row long">
                        <p><b>Status:</b></p>
                        <p>{item.properties.status}</p>
                    </div>
                    <div className="flex--row long">
                        <p><b>Farge:</b></p>
                        <p>{item.properties.color}</p>
                    </div>
                    <div className="flex--row long">
                        <p><b>Størrelse:</b></p>
                        <p>{item.properties.size}</p>
                    </div>
                    <div className="flex--row long">
                        <p><b>Fullt:</b></p>
                        <p>{item.properties.filled ? "Ja" : "Nei"}</p>
                    </div>
                    <div className="flex--column tall">
                        <p><b>I Røret:</b></p>
                        <div className="flex--row map--popup-list">
                        {cablesPipes.length> 0 ? (
                            cablesPipes.map((object, index) => (
                                <a href={`/${object.properties.type === 'Kabel' ? 'kabel' : 'ror'}/${object.id}`} key={index}>{object.properties.name}</a>
                            ))
                        ) : (
                            <p>Ingen rør eller kabler i dette røret.</p>
                        )}
                        </div>
                    </div>
                </div>
                <PopUpButtons
                    item={item}
                    type="line"
                    endpoint="ror"
                    handleUpdateCoordinates={handleUpdateCoordinates}
                />
            </div>
        </div>
    );
};

export default PopupPipes;
