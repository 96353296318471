import CreateIcon from "./CreateIcon";

const mapMarkers = {
    kum: CreateIcon({ className: "icon--kum", iconSize: [20, 20], iconAnchor:[10, 10]}),

    nodeAccess: CreateIcon({ className: "icon--node-access-marker" }),
    nodeAccessFull: CreateIcon({ className: "icon--node-access-disabled-marker" }),
    nodeDist: CreateIcon({ className: "icon--node-dist-marker" }),
    nodeDistFull: CreateIcon({ className: "icon--node-dist-disabled-marker" }),

    pipe: CreateIcon({ className: "icon--pipe-marker" }),
    cable: CreateIcon({ className: "icon--cable-marker" }),

    homeownerAssoc: CreateIcon({ className: "icon--homeownerassoc-marker" }),
    homeownerAssocNetadmin: CreateIcon({ className: "icon--homeownerassoc-netadmin-marker" }),

    newKum: CreateIcon({ className: "icon--new-kum", iconSize: [20, 20], iconAnchor:[10, 10]}),
    newNode: CreateIcon({ className: "icon--new-node-marker" }),
    moveNodeAccess: CreateIcon({ className: "icon--moving-node-marker" }),
    moveNodeDist: CreateIcon({ className: "icon--moving-node-dist-marker" }),
}

export default mapMarkers;