const Update = async (id, endpoint, data) => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${endpoint}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(`Failed to update data: ${errorMessage}`);
        }

        return response.ok;
    } catch (error) {
        console.error('Error updating data:', error);
        throw error;
    }
};

export default Update;
