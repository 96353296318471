import apiUrls from "../../../configs/api";
import GetById from "./GetById";

const GetUserType = async () => {
    try {
        const userId = sessionStorage.getItem('userId');
        const user = await GetById(apiUrls.get.users, userId);
        if (user) {
            return user.type;
        }
        return null;
    } catch (error) {
        console.error('Error fetching user type:', error);
        return null;
    }
};

export default GetUserType;
