import obosLogo from "../../assets/images/OBOSNetLogo.png";
import spotsLogo from "../../assets/images/spotsLogo2.png";
import VersionInfo from './VersionInfo';

const HeaderLoggedIn = (fixed) => {

    function handleLogout() {
        sessionStorage.clear();
        window.location.href = '/';
    }

    return (
        <header className={`header flex--row justify-content-sb align-items--center h-10vh`}>
            <div className="header--logo flex--row">
                <img src={obosLogo} alt="Obos opennet logo" className="header--obos-logo"/>
                <div className="flex--row header--spots align-items--center justify-content-center">
                    <img src={spotsLogo} alt="Spots Logo" className="header--spots-logo"/>
                    <div className="flex--column align-items--center justify-content-center">
                        <h3>SPOTS</h3>
                        <p><VersionInfo/></p>
                    </div>
                </div>
            </div>
            <div className="header--buttons flex--row justify-content-sb align-items--center">
                <a href="/kart">
                    Kart
                    <svg className="icon--compass"/>
                </a>
                <a href="/liste">
                    Liste <svg className="icon--list"/>
                </a>
                <a href="/profil">
                    Profil <svg className="icon--profile"/>
                </a>
                <button className="btn btn-primary-white-bg" onClick={handleLogout}>Logg ut</button>

            </div>
        </header>
    );
};

export default HeaderLoggedIn;
