import HeaderNotLoggedIn from "../../components/headers/headerNotLoggedIn";
import React from "react";
import NotFoundImage from "../../assets/images/404.png"
import GetAll from "../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../configs/api";

const NotFoundPage = () => {
    const checkToken = async (retries = 3, backoff = 300) => {
        try {
            const response = await GetAll(apiUrls.authentication.checkToken);
            if (response.token) {
                window.location.href = '/kart';
            }
            else {
                sessionStorage.clear();
                window.location.href = '/';
            }
        } catch (error) {
            if (retries > 0) {
                console.log(`Retrying... ${retries} attempts left`);
                await new Promise(res => setTimeout(res, backoff));
                return checkToken(retries - 1, backoff * 2);
            } else {
                console.error('Error checking token:', error);
                sessionStorage.clear();
                window.location.href = '/';
            }
        }
    };

    return (
        <div className="flex--column page-404 justify-content-center align-items--center">
            <HeaderNotLoggedIn/>
            <div className="page-404--you flex--column justify-content-center align-items--center">
                <p>Du er her...?</p>
                <svg className="icon--blue-arrow-down"/>
            </div>
            <div className="page-404--message flex--column justify-content-center align-items--center">
                <img src={NotFoundImage} alt="404 display"/>
                <p>Uh-oh! Ser ut som du har gått deg vill og endt opp på en utilgjengelig sti!  Den siden du leter etter er ikke markert på kartet - den er enten fjernet, omdøpt, midlertidig borte eller ennå ikke blitt utforsket! Men ikke få panikk! Gå til kartet for å navigere deg frem til riktig sti.</p>
                <button
                    className="btn btn-primary-white-bg"
                    onClick={checkToken}
                >
                    Gå til kart
                </button>
            </div>
        </div>

    )
}
export default NotFoundPage;