import React from 'react';
import SwitchDisplay from "./SwitchDisplay";

const InfoPageConnections = ({ item, headers, data, type }) => {
    return (
        <div className='flex--column'>
            <h3>Tilkoblinger</h3>
            <div className="flex--row info-page--connections">
                <div className="flex--column info-page--connections-headers">
                    {headers.map((header, index) => (
                        <p key={index} className="flex--row align-items--center">
                            <b>{header}</b>
                        </p>
                    ))}
                </div>
                <div className="flex--column info-page--connections-data">
                    {data.map((datum, index) => (
                        <div className="flex--row align-items--center justify-content-sb" key={index}>
                            <div className="flex--row align-items--center">
                                {datum.array ? (
                                    Array.isArray(datum.array) ? (
                                        datum.array.length > 0 ? (
                                            datum.array.map((object, idx) => {
                                                const name = object.properties?.name ?? object.name;
                                                let type = (object.properties?.type ?? object.type).toLowerCase();
                                                if (type === "rør") {
                                                    type = "ror";
                                                }
                                                return (
                                                    <a href={`/${type}/${object.id}`} key={idx}
                                                       className="flex--row align-items-center">
                                                        {name}
                                                    </a>
                                                );
                                            })
                                        ) : (
                                            <p>{datum.message}</p>
                                        )
                                    ) : (
                                        (() => {
                                            const name = datum.array.properties?.name ?? datum.array.name;
                                            let type = (datum.array.properties?.type ?? datum.array.type).toLowerCase();
                                            if (type === "rør") {
                                                type = "ror";
                                            }
                                            return (
                                                <a href={`/${type}/${datum.array.id}`}
                                                   className="flex--row align-items-center">
                                                    {name}
                                                </a>
                                            );
                                        })()
                                    )
                                ) : (
                                    <p>{datum.message}</p>
                                )}
                            </div>
                            <div>
                                {Array.isArray(datum.array) && (
                                    <p className="count">({datum.array.length})</p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

            </div>
            {type === 'node' && (
                <SwitchDisplay
                    header={"Switcher"}
                    data={item.switches}
                />
            )}
        </div>
    );
};

export default InfoPageConnections;
