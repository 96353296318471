import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

const ProfileMap = () => {
    return (
        <MapContainer
            center={[62.963776863118085, 8.557929841897224]}
            zoom={2}
            className="w-100vw h-150px profile--map"
            scrollWheelZoom={false}
            doubleClickZoom={false}
            dragging={false}
            zoomControl={false}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
        </MapContainer>
    );
};

export default ProfileMap;
