import { useParams } from 'react-router-dom';
import HeaderLoggedIn from "../../../components/headers/headerLoggedIn";
import MiniMap from "../../../components/maps/MiniMap";
import InfoPageHeader from "../../../components/headers/InfoPageHeader";
import React, {useEffect, useState} from "react";
import GetById from "../../../functions/apiCalls/Get/GetById";
import apiUrls from "../../../configs/api";
import InfoPageKumContents from "../../../components/containers/infoPage/InfoPageContents/InfoPageKumContents";
import InfoPageEditHeader from "../../../components/inputsSelectsCheckboxes/InfoPageEditHeader";
import InfoPageEditKumContents from "../../../components/containers/infoPage/InfoPageContents/Edit/InfoPageEditKumContents";
import HeaderButtons from "../../../components/buttons/HeaderButtons";
import To404 from "../../../functions/redirects/To404";
import GetUserType from '../../../functions/apiCalls/Get/GetUserType';

const KumInfoPage = () => {
    const { id } = useParams();
    const userId = sessionStorage.getItem('userId');
    const [kum, setKumData] = useState(null);
    const [newName, setNewName] = useState('');
    const [editLock, setEditLock] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const fetchUserType = async () => {
            const userType = await GetUserType();
            setUserType(userType);
        };
        fetchUserType();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const data = await GetById(apiUrls.get.kummer, id);
                    if (data) {
                        setKumData(data);
                        setEditLock(data.editLock);
                    }
                    else {
                        To404();
                    }
                }
            } catch (error) {
                console.error('Error fetching pipe data:', error);
            }
        };
        fetchData();
    }, [id, editMode, editLock]);

    useEffect(() => {
        document.body.classList.add("overflow-hidden");
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, []);

    return (
        <div>
            {kum && (
                <>
                    <HeaderLoggedIn/>
                    <div className="info-page flex--row">
                        <div className="info-page--map">
                            <MiniMap marker={kum} icon="kum" zoom={17}/>
                        </div>
                        <div className="info-page--content flex--column w-75vw">
                            <div className="info-page--content-header flex--row justify-content-sb align-items--center">
                                {kum && (
                                    !editMode ? (
                                        <InfoPageHeader
                                            name={kum.name}
                                            icon="kum"
                                        />
                                    ) : (
                                        <InfoPageEditHeader
                                            name={kum.name}
                                            icon="kum"
                                            setNewName={setNewName}
                                        />
                                    )
                                )}
                                {userType !== 'Read Only' && (
                                    <HeaderButtons
                                        editMode={editMode}
                                        setEditMode={setEditMode}
                                        editLock={editLock}
                                        setEditLock={setEditLock}
                                        userId={userId}
                                        userType={userType}
                                        item={kum}
                                        apiUrlLock={apiUrls.put.updateEditLockKum}
                                        apiUrlDelete={apiUrls.delete.deleteKum}
                                    />
                                )}
                            </div>
                            {!editMode ? (
                                <InfoPageKumContents kum={kum} userType={userType}/>
                            ) : (
                                <InfoPageEditKumContents kum={kum} newName={newName} setEditMode={setEditMode}/>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default KumInfoPage;
