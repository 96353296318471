const HandleKeyDown = (event, phoneInput) => {
    if (event.key === "Backspace" && phoneInput.selectionStart <= 3) {
        event.preventDefault();
    }

    if (event.key === "Delete" && phoneInput.selectionStart <= 3) {
        event.preventDefault();
    }
};

const HandleInput = (event, setFormData) => {
    let value = event.target.value;
    if (value.startsWith("+")) {
        value = "+" + value.slice(1).replace(/[^0-9]/g, '');
    } else {
        value = value.replace(/[^0-9]/g, '');
    }
    if (value.length > 11) {
        value = value.slice(0, 11);
    }
    setFormData(prevState => ({
        ...prevState,
        phoneNumber: value
    }));
};

export {HandleKeyDown, HandleInput};