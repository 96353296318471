import {Marker, Polyline, useMapEvents} from "react-leaflet";
import {useEffect, useState} from "react";
import icons from "../../iconsAndStyles/Icons";

const DrawLines = ({ color, isAdding, snapMarker }) => {
    const [coordinates, setCoordinates] = useState([]);
    const [currentCursorPosition, setCurrentCursorPosition] = useState(null);
    const [drawingStopped, setDrawingStopped] = useState(false);

    useMapEvents({
        mousemove: (e) => {
            if (isAdding && !drawingStopped) {
                setCurrentCursorPosition([e.latlng.lat, e.latlng.lng]);
            }
        },
        click: (e) => {
            if (isAdding && e.originalEvent.which === 1 && !drawingStopped) {
                let clickedCoordinates = [e.latlng.lat, e.latlng.lng];
                if (snapMarker) {
                    clickedCoordinates = [snapMarker.coordinates.latitude, snapMarker.coordinates.longitude];
                }
                setCoordinates(prevCoordinates => [...prevCoordinates, clickedCoordinates]);
            }
        },
        contextmenu: (e) => {
            if (isAdding) {
                setDrawingStopped(true);
            }
        },
    });

    useEffect(() => {
        if (!isAdding) {
            setCoordinates([]);
            setCurrentCursorPosition(null);
            setDrawingStopped(false);
        }
    }, [isAdding]);

    return (
        <>
            {/* Render the line being drawn */}
            {coordinates.length > 0 && (
                <>
                    {/* Render a marker for the first coordinate */}
                    <Marker position={coordinates[0]} icon={icons["firstLastCoordinate"]} />

                    {/* Render the polyline for the line coordinates */}
                    <Polyline
                        positions={coordinates}
                        color={color}
                    />

                    {/* Render the temporary polyline from the last coordinate to the cursor position */}
                    {isAdding && currentCursorPosition && !drawingStopped && (
                        <Polyline
                            positions={[coordinates[coordinates.length - 1], currentCursorPosition]}
                            color={color}
                        />
                    )}
                </>
            )}
            {/* Render the temporary marker at the cursor position */}
            {isAdding && currentCursorPosition && !drawingStopped && (
                <Marker position={currentCursorPosition} icon={icons["drawing"]} />
            )}
        </>
    );
};

export default DrawLines;




