import EmailRegex from "../formats-and-regex/EmailRegex";
import PhoneRegex from "../formats-and-regex/PhoneRegex";

const EmailPhoneChecks = (formData, setMessageData, phoneNumber) => {
    if (!EmailRegex(formData.email) && !PhoneRegex(formData.phoneNumber)) {
        setMessageData({
            type: 'warning',
            message: 'Ugyldig epost og mobilnummer!'
        });
        return false;
    }
    if (!EmailRegex(formData.email)) {
        setMessageData({
            type: 'warning',
            message: 'Ugyldig epost!'
        });
        return false;
    }
    if (!phoneNumber) {
        setMessageData({
            type: 'warning',
            message: 'Ugyldig mobilnummer! Må være +4799887766.'
        });
        return false;
    }

    return true;
}
export default EmailPhoneChecks;