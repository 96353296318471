const getAvailablePorts = (item) => {
    let totalPorts = 0;
    if (item.switches) {
        item.switches.forEach((switcher) => {
            switcher.ports.forEach((port) => {
                if (port.connectedEntityId === null) {
                    totalPorts++;
                }
            });
        });
    }
    return totalPorts;
}

export default getAvailablePorts;