const Delete = async (apiUrl, id) => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${apiUrl}/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            console.error('Failed to delete data:', response.statusText);
        }
        return response;
    } catch (error) {
        console.error('Error during deletion:', error);
    }
};

export default Delete;