import apiUrls from "../../../configs/api";
import React, { useEffect, useState } from "react";
import GetUserType from "../../../functions/apiCalls/Get/GetUserType";

const PopUpButtons = ({item, type, endpoint, handleUpdateCoordinates}) => {
    let apiUrl;
    const userId = sessionStorage.getItem('userId');

    const editLock = item.properties ? item.properties.editLock : item.editLock;
    const editLockUser = item.properties ? item.properties.editorLockUserId : item.editorLockUserId;
    const [userType, setUserType] = useState(null);
    
    let buttonLock = editLock && (editLockUser === userId || userType === "Admin" || userType === "Utvikler");
    
    useEffect(() => {
        const fetchUserType = async () => {
            const userType = await GetUserType();
            setUserType(userType);
        };

        fetchUserType();
    }, []);

    if (item && (item.properties?.type || item.type)) {
        switch (item.properties?.type || item.type) {
            case 'Kum':
                apiUrl = apiUrls.put.updateKumCoordinates;
                break;
            case 'Node':
                apiUrl = apiUrls.put.updateNodeCoordinates;
                break;
            case 'Kabel':
                apiUrl = apiUrls.put.updateCableCoordinates;
                break;
            case 'Rør':
                apiUrl = apiUrls.put.updatePipeCoordinates;
                break;
            case 'Borettslag':
                apiUrl = apiUrls.put.updateHomeownerAssocCoordinates;
                break;
            default:
                apiUrl = '';
        }
    }


    return (
        <div className="flex--column justify-content-sb">
            <button
                className="btn-popup btn-primary-white-bg d-flex justify-content-center"
                onClick={() => window.location.href = `/${endpoint}/${item.id}`}
            >
                Les mer
            </button>
            {userType !== 'Read Only' && (
                <button
                    className={`btn-popup btn-secondary-white-bg ${editLock ? (buttonLock ? '' : 'disabled') : ''} flex--row justify-content-center align-items--center`}
                    onClick={() => handleUpdateCoordinates(item.id, apiUrl, type)}
                    disabled={editLock && !buttonLock}
                >
                    Flytt
                    <svg
                        className={`icon--${editLock && !buttonLock ? 'green' : 'white'}-edit`}/>
                </button>
            )}
        </div>
    );
}

export default PopUpButtons;