const SelectWithData = ({ data, name, id, value, onChange,extraOption, extraOptionLabel, returnId }) => {
    return (
        <select
            name={name}
            id={id}
            value={value}
            onChange={onChange}
        >
            {extraOption && (
                <option value="">{extraOptionLabel}</option>
            )}
            {data.map((option, index) => {
                const optionValue = typeof option === 'string' ? option : option.value;
                const optionLabel = typeof option === 'string' ? option : option.label;
                const isDisabled = typeof option === 'object' && (option.full || option.tempClosed);
                const isTaken = typeof option === 'object' && (option.taken);

                return (
                    <option
                        key={index}
                        value={optionValue}
                        disabled={isDisabled || isTaken}
                    >
                        {optionLabel} {isDisabled ? (option.full ? '(Fullt)' : '(Midlertidig stengt/Under vedlikehold)') : ''} {isTaken ? '(Tatt)': ''}
                    </option>
                );
            })}
        </select>
    );
};

export default SelectWithData;
