
const CreateStyle = ({ color, fillOpacity = null }) => {
    return {
        color: color,
        weight: 3,
        fillColor: color,
        fillOpacity: fillOpacity,
    };
};

export default CreateStyle;