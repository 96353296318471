import React, { useEffect, useState } from "react";
import apiUrls from "../../../../configs/api";
import GetAll from "../../../../functions/apiCalls/Get/GetAll";
import InfoPageLog from "../InfoPageContainers/InfoPageLog";
import InfoPageData from "../InfoPageContainers/InfoPageData";
import findMarkerCoordinate from "../../../../functions/map/FindMarkerCoordinate";
import InfoPageFiles from "../InfoPageContainers/InfoPageFiles";
import InfoPageConnectionsHomeownerAssoc from "../InfoPageContainers/InfoPageConnectionsHomeownerAssoc";

const InfoPageHomeownerAssocContents = ({ homeownerAssoc, setHomeownerAssocData, userType }) => {
    const [nodes, setNodesData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (homeownerAssoc && homeownerAssoc.properties && homeownerAssoc.properties.nodes) {
                    const homeownerNodes = homeownerAssoc.properties.nodes.map(homeownerNode => homeownerNode.pointId);
                    const allNodes = await GetAll(apiUrls.get.nodes);
                    const filteredNodes = allNodes.filter(node => homeownerNodes.includes(node.id));
                    setNodesData(filteredNodes);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [homeownerAssoc]);

    return (
        <div className="info-page--content-data">
            <InfoPageLog item={homeownerAssoc}/>
            <div className="flex--row justify-content-sb">
                <InfoPageData
                    headers={["Org Nummer", "Status", "Hentet fra Netadmin", "Adresse", "Antall Leiligheter", "Breddegrad", "Lengdegrad", "Notater"]}
                    data={[
                        homeownerAssoc.properties.orgNumber ? homeownerAssoc.properties.orgNumber : 'Kunne ikke hente',
                        homeownerAssoc.properties.status,
                        homeownerAssoc.properties.netadminData ? 'Ja' : 'Nei',
                        homeownerAssoc.properties.address,
                        homeownerAssoc.properties.apartmentCount ?? 0,
                        findMarkerCoordinate(homeownerAssoc)[0],
                        findMarkerCoordinate(homeownerAssoc)[1],
                        homeownerAssoc.properties.notes ? homeownerAssoc.properties.notes : 'Ingen notater'
                    ]}
                />
                <InfoPageFiles
                    item={homeownerAssoc}
                    hasImages={false}
                    editLock={homeownerAssoc.properties.editLock}
                    editorLockUserId={homeownerAssoc.properties.editorLockUserId}
                    DefaultImage={null}
                    userType={userType}
                />
            </div>

            <InfoPageConnectionsHomeownerAssoc
                homeownerAssoc={homeownerAssoc}
                setHomeownerAssoc={setHomeownerAssocData}
                nodes={nodes}
                userType={userType}
                header="Antall Oppkoblede Leiligheter"
                data={homeownerAssoc.properties.portConnections === "0" ? 'Ingen oppkoblede leiligheter': homeownerAssoc.properties.portConnections}
            />
        </div>
    );
};

export default InfoPageHomeownerAssocContents;
