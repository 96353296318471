import nodeTypes from '../../../../assets/data/jsonFiles/Nodes/node-types.json';
import nodeStatusOptions from '../../../../assets/data/jsonFiles/Nodes/node_statuses.json';

const NewNodeInputs = ({ handleInputChange, currentGroupIndex, formData, setFormData }) => {
    return (
        <div className="map--add-form-inputs flex--column">
            {currentGroupIndex === 0 && (
                <>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Breddegrad</label>
                            <input
                                type='text'
                                id='latitude'
                                name='latitude'
                                placeholder='59.902712954570134'
                                value={formData.latitude}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Notater</label>
                            <input
                                type='text'
                                id='longitude'
                                name='longitude'
                                placeholder='10.783520510856157'
                                value={formData.longitude}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Type</label>
                            <select
                                name="nodeType"
                                id="nodeType"
                                value={formData.nodeType}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            >
                                {nodeTypes.nodeTypes.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Status</label>
                            <select
                                name="status"
                                id="status"
                                value={formData.status}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            >
                                {nodeStatusOptions.nodeStatusOptions.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Switcher</label>
                            <input
                                type='number'
                                id='switchCount'
                                name='switchCount'
                                min={1}
                                value={formData.switchCount}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Porter Per</label>
                            <select
                                name="portCount"
                                id="portCount"
                                value={formData.portCount}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            >
                                <option value={24}>24</option>
                                <option value={48}>48</option>
                            </select>
                        </div>
                    </div>
                </>

            )}
            {currentGroupIndex === 1 && (
                <>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Navn</label>
                            <input
                                type='text'
                                id='name'
                                name='name'
                                placeholder='Noden'
                                value={formData.name}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Adresse</label>
                            <input
                                type='text'
                                id='address'
                                name='address'
                                placeholder='Freserveien 1, 0195 Oslo'
                                value={formData.address}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Etasje</label>
                            <input
                                type='number'
                                id='floor'
                                name='floor'
                                placeholder='4'
                                value={formData.floor}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column whole-row'>
                            <label>Notater</label>
                            <input
                                type='text'
                                id='notes'
                                name='notes'
                                placeholder='Viktig tilleggsinformasjon'
                                value={formData.notes}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                </>
            )}

            {currentGroupIndex === 3 && (
                <div className='flex--column'>
                    <div className='flex--row'>


                    </div>

                    <div className='flex--row'>


                    </div>
                </div>
            )}
        </div>
    );
}

export default NewNodeInputs;