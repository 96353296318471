import React, {useEffect, useRef, useState} from "react";
import {LayerGroup, GeoJSON, Popup, Polyline, Marker} from "react-leaflet";
import findMarkerCoordinate from "../../../functions/map/FindMarkerCoordinate";
import icons from "../../iconsAndStyles/Icons";
import AllRenderFilter from "../../../functions/filter/filterRenders/AllRenderFilter";
import LineRenderFilter from "../../../functions/filter/filterRenders/LineRenderFilter";
import FindIfFullNode from "../../../functions/page-spesific/infoPage/FindIfFullNode";

const RenderLines = ({ handleUpdatedCoordinates, icon, data, style, PopupComponent, handleUpdateCoordinates, isUpdatingLine, updatingId, kummer, noder, hoveredResult, layerFilters, isAddingPoint, isAddingLine, isAddingPoly, isUpdatingPoint }) => {
    const [draggedCoordinates, setDraggedCoordinates] = useState([]);
    const markerRef = useRef(null);
    const hoveredId = hoveredResult && hoveredResult.properties && (hoveredResult.properties.pipeId || hoveredResult.properties.pipeInPipeId || hoveredResult.id);

    useEffect(() => {
        if (isUpdatingLine) {
            const updatedCoordinates = data.find((item) => item.id === updatingId)?.geometry.coordinates.map(coord => [coord[1], coord[0]]) || [];
            setDraggedCoordinates(updatedCoordinates);
            handleUpdatedCoordinates(updatedCoordinates);
        }
    }, [updatingId, data, isUpdatingLine]);

    useEffect(() => {
        if (hoveredResult && hoveredResult.properties && markerRef.current) {
            const hoveredId = hoveredResult.properties.pipeId || hoveredResult.properties.pipeInPipeId || hoveredResult.id;

            if (hoveredId && markerRef.current) {
                const marker = markerRef.current;
                marker.fire('click');

                setTimeout(() => {
                    marker.fire('click');
                }, 300);
            }
        }
    }, [hoveredResult]);

    const handleMarkerDragEnd = (index, event) => {
        const { target } = event;
        if (target) {
            const { _latlng } = target;
            if (_latlng) {
                const { lat, lng } = _latlng;
                let snappedLat = lat;
                let snappedLng = lng;

                let minDistance = Number.MAX_VALUE;
                let closestMarker = null;
                const snapDistanceThreshold = 0.00005;

                [kummer, noder].forEach(data => {
                    data.forEach(marker => {
                        const { latitude: markerLat, longitude: markerLng } = marker.coordinates;
                        const distance = Math.sqrt(Math.pow(markerLat - lat, 2) + Math.pow(markerLng - lng, 2));

                        if (!FindIfFullNode(marker) && distance <= snapDistanceThreshold && distance < minDistance) {
                            minDistance = distance;
                            closestMarker = marker;
                        }
                    });
                });

                if (closestMarker) {
                    snappedLat = closestMarker.coordinates.latitude;
                    snappedLng = closestMarker.coordinates.longitude;
                }

                const newCoordinates = [...draggedCoordinates];

                if (target.options.isMidMarker) {
                    const nextIndex = index;

                    const newCoordinate = [snappedLat, snappedLng];

                    newCoordinates.splice(nextIndex, 0, newCoordinate);
                } else {
                    newCoordinates[index] = [snappedLat, snappedLng];
                }

                setDraggedCoordinates(newCoordinates);

                setDraggedCoordinates(newCoordinates);
                handleUpdatedCoordinates(newCoordinates);
            }
        }
    };

    const markerEventHandlers = (index) => ({
        dragend: (event) => {
            handleMarkerDragEnd(index, event);
        }
    });

    const filteredData = data.filter((item) => {
        const allRenderResult = AllRenderFilter(layerFilters, item);
        const lineRenderResult = LineRenderFilter(layerFilters, item);
        if (hoveredId === item.id) {
            return true;
        }
        return allRenderResult && lineRenderResult;
    });

    return (
        <LayerGroup>
            {filteredData.map((item, index) => (
                <React.Fragment key={item.id}>
                    {isUpdatingLine && updatingId === item.id && (
                        <>
                            <Polyline
                                positions={draggedCoordinates}
                                pathOptions={style}
                            />
                            {draggedCoordinates.map((coord, index) => (
                                <Marker
                                    key={index}
                                    position={coord}
                                    draggable={true}
                                    eventHandlers={markerEventHandlers(index)}
                                    icon={icons["eachCoordinate"]}
                                />
                            ))}
                            {draggedCoordinates.slice(1).map((coord, index) => (
                                <Marker
                                    key={`mid-${index}`}
                                    position={[(coord[0] + draggedCoordinates[index][0]) / 2, (coord[1] + draggedCoordinates[index][1]) / 2]}
                                    draggable={true}
                                    eventHandlers={markerEventHandlers(index + 1)}
                                    icon={icons["eachCoordinate"]}
                                    isMidMarker={true}
                                />
                            ))}
                        </>
                    )}
                    {updatingId !== item.id && !item.properties.pipeId && !item.properties.pipeInPipeId &&
                        (
                            <>
                                <GeoJSON
                                    data={item}
                                    style={style}
                                    item={item}
                                    ref={hoveredId === item.id ? markerRef : null}
                                >
                                    {!(isAddingLine || isAddingPoint || isAddingPoly) && (
                                        <Popup position={findMarkerCoordinate(item)}>
                                            <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                        </Popup>
                                    )}
                                    {(isAddingPoint || isUpdatingPoint) &&
                                        item.geometry.coordinates.map((coord, coordIndex) => (
                                        <Marker
                                            key={`add-point-${coordIndex}`}
                                            position={[coord[1], coord[0]]}
                                            icon={icons["eachCoordinate"]}
                                        />
                                    ))}
                                </GeoJSON>

                                {layerFilters.all.icons && (item.properties.type === "Rør" ? layerFilters.pipes.icons : layerFilters.cables.icons)&& (
                                    <Marker position={findMarkerCoordinate(item)} icon={icon}>
                                        {!(isAddingLine || isAddingPoint || isAddingPoly) && (
                                            <Popup position={findMarkerCoordinate(item)}>
                                                <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                            </Popup>
                                        )}
                                    </Marker>
                                )}
                            </>
                        )
                    }
                </React.Fragment>
            ))}
        </LayerGroup>
    );
};

export default RenderLines;









